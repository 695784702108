import React, { setGlobal, getGlobal } from "reactn";
import ActieKnoppen from "./ActieKnoppen";
// import Icon from "./Icon";
import ListItem from "@material-ui/core/ListItem";
import Chip from "@material-ui/core/Chip";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Typography, Grid } from "@material-ui/core";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import SendIcon from "@material-ui/icons/Send";
import Icon from "../Components/Icon";

export default class Slurfcombinatie extends React.Component {
	constructor(props) {
		super(props);
	}

	state = {
		config: false
		//currentTube: 0
	};

	componentDidUpdate(prevProps) {
		if (prevProps.status !== this.props.status) {
			this.forceUpdate();
		}
	}

	getConfig = async () => {
		this.setState({
			config: {
				doortellen: false
			}
		});

		this.doortelIncrement();
	};

	componentDidMount() {
		this.getConfig();
		this.doortelIncrement();
	}

	doortelIncrement = async () => {
		let a = await getGlobal();
		if (a.totalTubes === 0) return;
		if (a.currentTube > a.totalTubes) {
			await this.setGlobal({ currentTube: 0 });
		}

		a = await getGlobal();
		this.setState({
			currentTube: a.currentTube
		});

		await this.setGlobal({
			currentTube: a.totalTubes > a.currentTube ? a.currentTube + 1 : 0
		});

		this.forceUpdate();
	};

	render() {
		return (
			<>
				<ListItem>
					<Grid
						container
						direction="row"
						justify="space-between"
						alignItems="center"
						spacing={3}
					>
						<Grid item xs={6} md={3}>
							{this.props.status === 0 ||
							this.props.status > 0 ? (
								<>
									<Icon code={this.props.status} />
								</>
							) : (
								""
							)}
						</Grid>
						<Grid item style={{ textAlign: "left" }}>
							<Typography
								variant="button"
								display="block"
								style={{ marginLeft: 10 }}
							>
								{this.props.status === 0 ||
								this.props.status > 0
									? `${
											this.props.data.configuration === 2
												? "Slurfcombinatie"
												: this.props.data
														.configuration === 1
												? "Slurf"
												: "ONBEKEND"
									  } ${
											this.state.config.doortellen
												? this.state.currentTube
												: parseInt(this.props.nummer) +
												  1
									  }`
									: ""}
							</Typography>
						</Grid>
						{this.global.user.admin ? (
							<Grid item xs={12} md={3}>
								{[
									"serial",
									"type",
									"configuration",
									"networkID",
									"softwareVersion"
								].map((ev, k) => (
									<Chip
										style={{ textTransform: "capitalize", margin: '.2rem' }}
										label={`${ev} : ${this.props.data[ev]}`}
										key={k}
									/>
								))}
							</Grid>
						) : (
							""
						)}
						<Grid item xs={12} md={2}>
							<ActieKnoppen
								status={this.props.status}
								networkID={this.props.networkID}
								nummer={this.props.nummer}
								data={this.props.data}
							/>
						</Grid>
					</Grid>
				</ListItem>
			</>
		);
	}
}
