import React, { useEffect, setGlobal, getGlobal } from "reactn";
import { Route, useHistory } from "react-router-dom";
import PropTypes from "prop-types";

const RouteWithLayout = props => {
	const { layout: Layout, component: Component, ...rest } = props;
	const history = useHistory();

	const checkCookie = () => {
		try {
			let gs = getGlobal();
			let jwtCookie = localStorage.getItem("jwtAuth");
			jwtCookie = JSON.parse(jwtCookie);

			if (!jwtCookie) {
				return history.push("/login");
			} else if (!jwtCookie.authorization) {
				return history.push("/login");
			}
			if (gs.user.device_hash && gs.user.name) {
				return history.push(props.path);
			}
			let userCookie = localStorage.getItem("user");
			if (userCookie !== null && userCookie) {
				userCookie = JSON.parse(userCookie);
				if (userCookie.device_hash && userCookie.name) {
					setGlobal({
						user: userCookie
					});
					return history.push(props.path);
				} else {
					return history.push("/login");
				}
			} else {
				return history.push("/login");
			}
		} catch (err) {
			if (err) {
				return history.push("/login");
			}
		}
		console.log(gs.user);
	};

	useEffect(() => {
		checkCookie();
	}, []);

	return (
		<Route
			{...rest}
			render={matchProps => (
				<Layout>
					<Component {...matchProps} />
				</Layout>
			)}
		/>
	);
};

RouteWithLayout.propTypes = {
	component: PropTypes.any.isRequired,
	layout: PropTypes.any.isRequired,
	path: PropTypes.string
};

export default RouteWithLayout;
