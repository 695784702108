import React, { useGlobal } from 'reactn';
import palette from 'theme/palette';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment'
import * as API from '../../../../api/api'
import {
  IconButton,
  Typography
} from '@material-ui/core';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button
} from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import { options } from './chart';
import localization from 'moment/locale/nl';
moment.locale('nl', localization);

const useStyles = makeStyles(() => ({
  root: {},
  chartContainer: {
    height: 400,
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const LatestSales = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const [system] = useGlobal('system')

  let aantalPerDag = [];
  let dagen = [];

  if (system.events) {
    system.events.map((ev, k) => {
      aantalPerDag.push(ev.total)
      dagen.push(moment(ev._id).lang('nl').format("D MMM"))
    })
  }

  const refreshData = () => {
    API.getSystem()
  }

  const data = {
    labels: dagen,
    datasets: [
      {
        label: 'Aantal acties',
        backgroundColor: palette.primary.main,
        data: aantalPerDag
      }
    ]
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
           <CardHeader
        action={
          <IconButton size="small" onClick={()=>{refreshData()}}>
            <RefreshIcon />
          </IconButton>
        }
        title="Acties per dag"
      />
      <Divider />
      <CardContent>
        <div className={classes.chartContainer}>
          <Bar
            data={data}
            options={options}
          />
        </div>
      </CardContent>
    </Card>
  );
};

LatestSales.propTypes = {
  className: PropTypes.string
};

export default LatestSales;
