import React, { useGlobal } from "reactn";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Card, CardContent, Grid, Typography, Avatar } from "@material-ui/core";
import InsertChartIcon from "@material-ui/icons/InsertChartOutlined";

const useStyles = makeStyles(theme => ({
	root: {
		height: "100%"
	},
	content: {
		alignItems: "center",
		display: "flex"
	},
	title: {
		fontWeight: 700
	},
	avatar: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
		height: 56,
		width: 56
	},
	icon: {
		height: 32,
		width: 32
	},
	progress: {
		marginTop: theme.spacing(3)
	}
}));

const ActiesVandaag = props => {
	const { className, ...rest } = props;

	const [system] = useGlobal("system");

	let today = 0;

	if (system.events) {
		today = system.events[system.events.length - 1].total;
	}

	const classes = useStyles();

	return (
		<Card {...rest} className={clsx(classes.root, className)}>
			<CardContent>
				<Grid container justify="space-between">
					<Grid item>
						<Typography
							className={classes.title}
							color="textSecondary"
							gutterBottom
							variant="body2"
						>
							ACTIES VANDAAG
						</Typography>
						<Typography variant="h3">{today}</Typography>
					</Grid>
					<Grid item>
						<Avatar className={classes.avatar}>
							<InsertChartIcon className={classes.icon} />
						</Avatar>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

ActiesVandaag.propTypes = {
	className: PropTypes.string
};

export default ActiesVandaag;
