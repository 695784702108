import React, { useState, useGlobal, setGlobal } from "reactn";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import * as API from "../../../../api/api";
import {
	Card,
	CardHeader,
	CardContent,
	CardActions,
	Divider,
	Grid,
	Button,
	Typography,
	Snackbar,
	TextField
} from "@material-ui/core";
import { useEffect } from "react";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles(() => ({
	root: {}
}));

const AccountProfile = props => {
	const { className, ...rest } = props;

	const classes = useStyles();
	const [user] = useGlobal("user");
	const [hasDone] = useGlobal("hasDone");

	const [values, setValues] = useState({
		name: user.name,
		oldpassword: "",
		newpassword: "",
		newpasswordconfirm: ""
	});

	const [error, setError] = useState("");

	const handleChange = event => {
		setValues({
			...values,
			[event.target.name]: event.target.value
		});
	};

	const onSave = async () => {
		if (values.newpassword !== values.newpasswordconfirm)
			return setError(
				"De nieuw ingevoerde wachtwoorden komen niet overeen."
			);
		try {
			await API.doSavePassword(values);
			setError("");
		} catch (error) {
			setError(error.message);
		} finally {
			setGlobal({
				hasDone: true
			});
		}
	};

	return (
		<Card {...rest} className={clsx(classes.root, className)}>
			<Snackbar
				open={hasDone}
				autoHideDuration={6000}
				onClose={() => {
					setGlobal({ hasDone: false });
				}}
			>
				<Alert severity="info">Wijzigingen opgeslagen</Alert>
			</Snackbar>
			<form autoComplete="off" noValidate>
				<CardHeader
					subheader="Wijzig uw wachtwoord"
					title="Wachtwoord Wijzigen"
				/>
				<Divider />
				<CardContent>
					<Grid container spacing={3}>
						<Grid item md={12} xs={12}>
							<TextField
								fullWidth
								label="Huidig wachtwoord"
								margin="dense"
								name="oldpassword"
								onChange={handleChange}
								required
								type="password"
								value={values.oldpasword}
								variant="outlined"
							/>
						</Grid>
						<Grid item md={12} xs={12}>
							<TextField
								fullWidth
								label="Nieuw wachtwoord"
								margin="dense"
								name="newpassword"
								onChange={handleChange}
								required
								type="password"
								value={values.newpassword}
								variant="outlined"
							/>
						</Grid>
						<Grid item md={12} xs={12}>
							<TextField
								fullWidth
								label="Wachtwoord bevestigen"
								margin="dense"
								name="newpasswordconfirm"
								onChange={handleChange}
								required
								type="password"
								value={values.newpasswordconfirm}
								variant="outlined"
							/>

							<Typography variant="body2" gutterBottom>
								{error}
							</Typography>
						</Grid>
					</Grid>
				</CardContent>
				<Divider />
				<CardActions>
					<Button
						color="primary"
						variant="contained"
						onClick={() => {
							onSave();
						}}
					>
						Opslaan
					</Button>
				</CardActions>
			</form>
		</Card>
	);
};

AccountProfile.propTypes = {
	className: PropTypes.string
};

export default AccountProfile;
