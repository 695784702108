import React, { useState, useGlobal, useEffect, setGlobal } from "reactn";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/styles";
import { useMediaQuery } from "@material-ui/core";
import { Redirect } from "react-router-dom";
import { Sidebar, Topbar, Footer } from "./components";
import * as API from "../../api/api";

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
	root: {
		paddingTop: 56,
		height: "100%",
		[theme.breakpoints.up("sm")]: {
			paddingTop: 64
		}
	},
	shiftContent: {
		paddingLeft: 240
	},
	content: {
		height: "100%"
	}
}));

const Main = props => {
	const { children } = props;

	const classes = useStyles();
	const theme = useTheme();
	const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
		defaultMatches: true
	});

	const [openSidebar, setOpenSidebar] = useState(false);

	const handleSidebarOpen = () => {
		setOpenSidebar(true);
	};

	const handleSidebarClose = () => {
		setOpenSidebar(false);
	};

	const shouldOpenSidebar = isDesktop ? true : openSidebar;

	const [loggedInUser] = useGlobal("user");

	useEffect(() => {
		// API.getStatus(user.device_hash);
		API.doSubscribeWebSocket();
		if (loggedInUser) {
			API.setDeviceType(loggedInUser.name);
		}
	}, [loggedInUser]);

	let [hasError] = useGlobal("hasError");
	let [errorMessage] = useGlobal("errorMessage");

	return (
		<div
			className={clsx({
				[classes.root]: true,
				[classes.shiftContent]: isDesktop
			})}
		>
			<Topbar onSidebarOpen={handleSidebarOpen} />
			<Sidebar
				onClose={handleSidebarClose}
				open={shouldOpenSidebar}
				variant={isDesktop ? "persistent" : "temporary"}
			/>
			<main className={classes.content}>
				<Snackbar
					open={hasError}
					autoHideDuration={6000}
					onClose={() => {
						setGlobal({ hasError: false });
					}}
				>
					<Alert severity="error">{errorMessage}</Alert>
				</Snackbar>
				{children}
				<Footer />
			</main>
		</div>
	);
};

Main.propTypes = {
	children: PropTypes.node
};

export default Main;
