import React, { setGlobal, getGlobal } from "reactn";
import Slurfcombinatie from "./Slurfcombinatie";
import NetworkIDFormat from "./NetworkIDFormat";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import { Typography } from "@material-ui/core";
export default class NotifyDetail extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.status !== this.props.status) {
			this.forceUpdate();
		}
	}

	render() {
		return (
			<List
				component="nav"
				aria-labelledby="nested-list-subheader"
				subheader={
					<Typography
						variant="button"
						display="block"
						style={{
							marginLeft: 10
						}}
					>
						NotifyDetail{" "}
						{this.props.data ? (
							<NetworkIDFormat
								nummer={this.props.data.networkID}
							/>
						) : (
							""
						)}
					</Typography>
				}
				// className={classes.root}
			>
				{this.props.data.status.tubeStatus ? (
					<>
						{this.props.data.status.tubeStatus.map((item, i) => {
							return (
								<div
									key={i}
									style={{
										borderBottom: "1px solid #ebebeb",
										paddingBottom: "0.5rem",
										paddingTop: "0.5rem"
									}}
								>
									<Slurfcombinatie
										nummer={i}
										key={i}
										status={item}
										networkID={this.props.data.networkID}
										data={this.props.data}
									/>
								</div>
							);
						})}
					</>
				) : (
					"Loading.."
				)}
			</List>
		);
	}
}
