import React, { useState, setGlobal } from "reactn";
import { Link as RouterLink, useHistory } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
	AppBar,
	Toolbar,
	Badge,
	Hidden,
	IconButton,
	Typography
} from "@material-ui/core";
import ExitToApp from "@material-ui/icons/ExitToApp";
import MenuIcon from "@material-ui/icons/Menu";

const useStyles = makeStyles(theme => ({
	root: {
		boxShadow: "none",
		backgroundColor: "#5666c3"
	},
	title: {
		color: "white"
	},
	flexGrow: {
		flexGrow: 1
	},
	signOutButton: {
		marginLeft: theme.spacing(1)
	}
}));

const Topbar = props => {
	const { className, onSidebarOpen, ...rest } = props;

	const history = useHistory();
	const onLogOut = () => {
		localStorage.removeItem("user");
		localStorage.removeItem("jwtAuth");
		setGlobal({
			user: []
		});
		history.push("/login");
	};

	const classes = useStyles();

	const [notifications] = useState([]);

	return (
		<AppBar {...rest} className={clsx(classes.root, className)}>
			<Toolbar>
				<RouterLink to="/">
					<Typography className={classes.title} variant="h4" noWrap>
						Luchtslurf App
					</Typography>
				</RouterLink>
				<div className={classes.flexGrow} />
				<Hidden>
					<IconButton
						color="inherit"
						onClick={() => {
							onLogOut();
						}}
					>
						<ExitToApp />
					</IconButton>
				</Hidden>
				<Hidden lgUp>
					<IconButton color="inherit" onClick={onSidebarOpen}>
						<MenuIcon />
					</IconButton>
				</Hidden>
			</Toolbar>
		</AppBar>
	);
};

Topbar.propTypes = {
	className: PropTypes.string,
	onSidebarOpen: PropTypes.func
};

export default Topbar;
