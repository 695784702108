import React, { useState, useEffect, setGlobal } from "reactn";
import { Link as RouterLink, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import validate from "validate.js";
import { makeStyles } from "@material-ui/styles";
import {
	Grid,
	Button,
	TextField,
	Link,
	Typography
} from "@material-ui/core";
import * as API from "../../api/api";

const schema = {
	name: {
		length: {
			maximum: 64
		}
	},
	password: {
		length: {
			maximum: 128
		}
	}
};

const useStyles = makeStyles(theme => ({
	root: {
		backgroundColor: theme.palette.background.default,
		height: "100%"
	},
	grid: {
		height: "100%"
	},
	quoteContainer: {
		[theme.breakpoints.down("md")]: {
			display: "none"
		}
	},
	quote: {
		backgroundColor: theme.palette.neutral,
		height: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		backgroundImage: "url(/images/luchtslurf-bg.jpg)",
		backgroundSize: "cover",
		backgroundRepeat: "no-repeat",
		backgroundPosition: "center"
	},
	quoteInner: {
		textAlign: "center",
		flexBasis: "600px"
	},
	quoteText: {
		color: theme.palette.white,
		fontWeight: 300
	},
	name: {
		marginTop: theme.spacing(3),
		color: theme.palette.white
	},
	bio: {
		color: theme.palette.white
	},
	contentContainer: {},
	content: {
		height: "100%",
		display: "flex",
		flexDirection: "column"
	},
	contentHeader: {
		display: "flex",
		alignItems: "center",
		paddingTop: theme.spacing(5),
		paddingBototm: theme.spacing(2),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2)
	},
	logoImage: {
		marginLeft: theme.spacing(4)
	},
	contentBody: {
		flexGrow: 1,
		display: "flex",
		alignItems: "center",
		[theme.breakpoints.down("md")]: {
			justifyContent: "center"
		}
	},
	form: {
		paddingLeft: 100,
		paddingRight: 100,
		paddingBottom: 125,
		flexBasis: 700,
		[theme.breakpoints.down("sm")]: {
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2)
		}
	},
	title: {
		marginTop: theme.spacing(3)
	},
	socialButtons: {
		marginTop: theme.spacing(3)
	},
	socialIcon: {
		marginRight: theme.spacing(1)
	},
	sugestion: {
		marginTop: theme.spacing(2)
	},
	textField: {
		marginTop: theme.spacing(2)
	},
	signInButton: {
		margin: theme.spacing(2, 0)
	}
}));

const SignIn = props => {
	const { history } = props;

	const classes = useStyles();

	const [formState, setFormState] = useState({
		isValid: false,
		values: {},
		touched: {},
		errors: {}
	});

	useEffect(() => {
		const errors = validate(formState.values, schema);

		setFormState(formState => ({
			...formState,
			isValid: errors ? false : true,
			errors: errors || {}
		}));
	}, [formState.values]);

	const handleChange = event => {
		event.persist();
		setFormState(formState => ({
			...formState,
			values: {
				...formState.values,
				[event.target.name]:
					event.target.type === "checkbox"
						? event.target.checked
						: event.target.value
			},
			touched: {
				...formState.touched,
				[event.target.name]: true
			}
		}));
	};

	const checkCookie = () => {
		let userCookie = localStorage.getItem("user");
		if (userCookie !== null && userCookie) {
			userCookie = JSON.parse(userCookie);
			if (userCookie.device_hash && userCookie.name) {
				setGlobal({
					user: userCookie
				});
				return history.push("/dashboard");
			}
		}
	};

	useEffect(() => {
		checkCookie();
	}, []);

	const [error, setError] = useState("");

	const handleSignIn = async event => {
		event.preventDefault();
		try {
			setError("");
			await API.doLogin(formState.values);
			return history.push("/dashboard");
		} catch (error) {
			setError(error.message);
		}
	};

	const hasError = field =>
		formState.touched[field] && formState.errors[field] ? true : false;

	return (
		<div className={classes.root}>
			<Grid className={classes.grid} container>
				<Grid className={classes.quoteContainer} item lg={5}>
					<div className={classes.quote}>
						<div className={classes.quoteInner}>
							<Typography
								className={classes.quoteText}
								variant="h1"
							>
								Luchtslurf App
							</Typography>
							<div className={classes.person}>
								<Typography
									className={classes.name}
									variant="body1"
								>
									Firma Plat
								</Typography>
								<Typography
									className={classes.bio}
									variant="body2"
								>
									Sint Annaparochie
								</Typography>
							</div>
						</div>
					</div>
				</Grid>
				<Grid className={classes.content} item lg={7} xs={12}>
					<div className={classes.content}>
						<div className={classes.contentBody}>
							<form
								className={classes.form}
								onSubmit={handleSignIn}
							>
								<Typography
									className={classes.title}
									variant="h2"
								>
									Aanmelden
								</Typography>
								<TextField
									className={classes.textField}
									error={hasError("name")}
									fullWidth
									helperText={
										hasError("name")
											? formState.errors.name[0]
											: null
									}
									label="Gebruikersnaam"
									name="name"
									onChange={handleChange}
									type="text"
									value={formState.values.name || ""}
									variant="outlined"
								/>
								<TextField
									className={classes.textField}
									error={hasError("password")}
									fullWidth
									helperText={
										hasError("password")
											? formState.errors.password[0]
											: null
									}
									label="Wachtwoord"
									name="password"
									onChange={handleChange}
									type="password"
									value={formState.values.password || ""}
									variant="outlined"
								/>
								<Button
									className={classes.signInButton}
									color="primary"
									disabled={
										!formState.values.name ||
										!formState.values.password
									}
									fullWidth
									size="large"
									type="submit"
									variant="contained"
								>
									Aanmelden
								</Button>
								<Typography
									color="textSecondary"
									variant="body1"
								>
									{error}
								</Typography>
								<br />
								<Typography
									color="textSecondary"
									variant="body1"
								>
									Nog geen account?{" "}
									<a
										href="mailto:support@plattech.nl"
										className={".MuiTypography-h6"}
										style={{
											color: "#3f51b5",
											fontWeight: 500
										}}
										target="_blank"
										variant="h6"
									>
										Neem contact op
									</a>
								</Typography>
								<br />
								<Typography
									color="textSecondary"
									variant="body1"
								>
									Wachtwoord vergeten?{" "}
									<Link
										component={RouterLink}
										to="/wachtwoordvergeten"
										exact={"true"}
										variant="h6"
									>
										Wachtwoord herstellen
									</Link>
								</Typography>
							</form>
						</div>
					</div>
				</Grid>
			</Grid>
		</div>
	);
};

SignIn.propTypes = {
	history: PropTypes.object
};

export default withRouter(SignIn);
