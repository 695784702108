import React from "react";
import { Grid, Typography, Fab, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

export default class Icon extends React.Component {
	iconsArray = [
		{
			// 0
			icon: "fa fa-power-off",
			color: "",
			text: "Opgestart"
		},
		{
			// 1
			icon: "fa fa-exclamation-triangle",
			color: "red",
			text: "Error"
		},
		{
			// 2
			icon: "fa fa-battery-full",
			color: "green",
			text: "Vol"
		},
		{
			// 3
			icon: "fa fa-battery-empty",
			color: "green",
			text: "Leeg"
		},
		{
			// 4
			icon: ["fas fa-sync", "fa fa-battery-three-quarters"],
			color: "green",
			text: "Wordt bijgevuld"
		},
		{
			// 5
			icon: "fa fa-battery-full",
			color: "#ffe000",
			text: "Vul aanvraag"
		},
		{
			// 6
			icon: "fa fa-battery-empty",
			color: "#ffe000",
			text: "Leeg aanvraag"
		},
		{
			// 7
			icon: ["fas fa-sync", "fa fa-battery-full"],
			color: "orange",
			text: "Wordt gevuld"
		},
		{
			// 8
			icon: ["fas fa-sync", "fa fa-battery-empty"],
			color: "orange",
			text: "Wordt geleegd"
		},
		{
			// 9
			icon: [
				"fas fa-sync",
				"fa fa-battery-full",
				"fa fa-caret-square-o-up"
			],
			color: "orange",
			text: "Horizontale wordt gevuld"
		},
		{
			// 10
			icon: "fa fa-exclamation-triangle",
			color: "red",
			text: "Klep 1 storing"
		},
		{
			// 11
			icon: "fa fa-exclamation-triangle",
			color: "red",
			text: "Klep 2 storing"
		},
		{
			// 12
			icon: "fa fa-exclamation-triangle",
			color: "red",
			text: "Klep 3 storing"
		},
		{
			// 13
			icon: "fa fa-exclamation-triangle",
			color: "red",
			text: "Klep 4 storing"
		},
		{
			// 14
			icon: "fa fa-exclamation-triangle",
			color: "red",
			text: "Klep 5 storing"
		},
		{
			// 15
			icon: "fa fa-exclamation-triangle",
			color: "red",
			text: "Klep 6 storing"
		},
		{
			// 16
			icon: "fa fa-exclamation-triangle",
			color: "red",
			text: "Klep 7 storing"
		},
		{
			// 17
			icon: "fa fa-exclamation-triangle",
			color: "red",
			text: "Klep 8 storing"
		},
		{
			// 18
			icon: "fa fa-exclamation-triangle",
			color: "red",
			text: "Externe klep storing"
		},
		{
			// 19
			icon: "fa fa-wrench",
			color: "#ffe000",
			text: "Reset aanvraag"
		},
		{
			// 20
			icon: "fa fa-wrench",
			color: "green",
			text: "Reset in behandeling"
		},
		{
			// 21
			icon: "fa fa-cogs",
			color: "#ffe000",
			text: "Test aanvraag"
		},
		{
			// 22
			icon: "fa fa-cogs",
			color: "green",
			text: "Test in behandeling"
		}
	];

	render() {
		let style = {
			color: this.iconsArray[this.props.code].color
		};

		return (
			<>
				
						<span className={"p-2 iconspacing"}>
							{typeof this.iconsArray[this.props.code].icon ===
							"object"
								? this.iconsArray[this.props.code].icon.map(
										(item, i) => {
											if (item !== "fas fa-sync") {
												return (
													<i
														className={
															"m-2 " + item
														}
														key={i}
														style={style}
													/>
												);
											} else {
												return (
													<i
														key={i}
														className={
															item + " fa-spin"
														}
													/>
												);
											}
										}
								  )
								: ""}
							{typeof this.iconsArray[this.props.code].icon !==
							"object" ? (
								<i
									className={
										"m-2 " +
										this.iconsArray[this.props.code].icon
									}
									style={style}
								/>
							) : (
								""
							)}
						</span>

						<Typography
							variant="button"
							display="block"
						>
							{this.iconsArray[this.props.code].text
								? this.iconsArray[this.props.code].text
								: "__"}
						</Typography>
					
			</>
		);
	}
}
