import { setGlobal, getGlobal } from "reactn";
import socketIOClient from "socket.io-client";
import _ from "lodash";

let endpoint = "http://api.plattech.nl:3001";
let socket = socketIOClient("http://api.plattech.nl:3002");

let location = window.location.href;

if (location.indexOf("localhost") !== -1) {
	console.log(`Localhost detected, using localhost endpoints`);
	endpoint = "http://localhost:3001";
	socket = socketIOClient("http://localhost:3002");
}

export const doSubscribeWebSocket = () => {
	let user = getGlobal().user;
	if (!user.device_hash) return;
	socket.off(user.device_hash).on(user.device_hash, data => {
		setGlobal({
			status: data
		});
	});
};

socket.on("connect", () => {
	console.log("Socket has connected.");
});

socket.on("disconnect", () => {
	console.log("Socket is closed. Reconnect will be attempted in 5 second.");
	setTimeout(function() {
		doSubscribeWebSocket();
	}, 5000);
});

socket.on("connect_error", () => {
	console.log("Socket is closed. Reconnect will be attempted in 5 second.");
	setTimeout(function() {
		doSubscribeWebSocket();
	}, 5000);
});

const getHeaders = () => {
	let authorization = undefined;

	let jwtCookie = localStorage.getItem("jwtAuth");
	jwtCookie = JSON.parse(jwtCookie);
	if (jwtCookie) {
		if (jwtCookie.authorization) {
			authorization = jwtCookie.authorization;
		}
	}
	if (authorization) {
		return {
			"Content-Type": "application/json",
			authorization: authorization
		};
	}
};

export const doUnsubscribeWebSocket = () => {
	console.log("exiting component, unsubscribe!");
	socket.disconnect();
};

export const setDeviceType = async name => {
	if (!name) return;
	const requestOptions = {
		method: "GET",
		headers: getHeaders()
	};
	fetch(`${endpoint}/api/v1/userdevice/${name}`, requestOptions)
		.then(res => res)
		.then(
			result => {
				//console.log(result)
			},
			error => {
				console.log(error);
			}
		);
};
export const getUsers = async () => {
	const requestOptions = {
		method: "POST",
		headers: getHeaders()
	};
	return fetch(`${endpoint}/api/v1/users`, requestOptions)
		.then(res => res.json())
		.then(
			result => {
				setGlobal({
					users: result
				});
				return result;
			},
			error => {
				console.log(error);
			}
		);
};

export const doGetDatausage = async () => {
	const requestOptions = {
		method: "POST",
		headers: getHeaders()
	};
	return fetch(`${endpoint}/api/v1/datausage`, requestOptions)
		.then(res => res.json())
		.then(
			result => {
				setGlobal({
					datausage: result
				});
			},
			error => {
				console.log(error);
			}
		);
};

export const getDevices = async () => {
	const requestOptions = {
		method: "POST",
		headers: getHeaders()
	};
	return fetch(`${endpoint}/api/v1/devices`, requestOptions)
		.then(res => res.json())
		.then(
			result => {
				setGlobal({
					devices: result
				});
			},
			error => {
				console.log(error);
				setGlobal({
					hasError: true,
					errorMessage:
						"Apparaten konden niet worden opgehaald, controleer uw verbinding.."
				});
			}
		);
};

export const getDeviceActionLogHash = async (device_hash, until) => {
	const requestOptions = {
		method: "GET",
		headers: getHeaders()
	};
	return fetch(`${endpoint}/api/v1/device/log/${device_hash}/${until}`, requestOptions)
		.then(res => res.json())
		.then(
			result => {
				setGlobal({
					deviceactionlogshash: result					
				});		
				//console.log(JSON.stringify(result));
				return result;

			},
			error => {
				console.log(error);
				setGlobal({
					hasError: true,
					errorMessage:
						"Hash of datum onjuist .."
				});
			}
		);
};


export const getDeviceActionLog = async () => {
const requestOptions = {
	method: "GET",
	headers: getHeaders()
};
return fetch(`${endpoint}/api/v1/device/logs`, requestOptions)
	.then(res => res.json())
	.then(
		result => {
			setGlobal({
				deviceactionlogs: result
			});				
		},
		error => {
			console.log(error);
			setGlobal({
				hasError: true,
				errorMessage:
					"Logs konden niet worden opgehaald, controleer uw verbinding.."
			});
		}
	);
};

export const getSystem = async () => {
	const requestOptions = {
		method: "POST",
		headers: getHeaders()
	};
	fetch(`${endpoint}/api/v1/system`, requestOptions)
		.then(res => res.json())
		.then(
			result => {
				setGlobal({
					system: result
				});
			},
			error => {
				console.log(error);
			}
		);
};

export const doLogin = async formData => {
	const requestOptions = {
		method: "POST",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify(formData)
	};
	await fetch(`${endpoint}/api/v1/login/`, requestOptions)
		.then(res => res.json())
		.then(
			result => {
				if (!result.error) {
					localStorage.setItem("user", JSON.stringify(result));
					localStorage.setItem(
						"jwtAuth",
						JSON.stringify({ authorization: result.accessToken })
					);
					setGlobal({
						user: result
					});
				} else {
					throw new Error(result.error);
				}
			},
			error => {
				console.log(error);
			}
		);
};

export const doDeleteLayout = async device_hash => {
	const requestOptions = {
		method: "POST",
		headers: getHeaders(),
		body: JSON.stringify(device_hash)
	};
	await fetch(`${endpoint}/api/v1/device/deletelayout`, requestOptions)
		.then(res => res.json())
		.then(result => {
			return result;
		});
};

export const doEmptyStatus = async device_hash => {
	const requestOptions = {
		method: "POST",
		headers: getHeaders(),
		body: JSON.stringify({ device_hash })
	};
	return await fetch(`${endpoint}/api/v1/device/resetstatus`, requestOptions)
		.then(res => res.json())
		.then(result => {
			return result;
		});
};

export const doUpdateLayout = async formData => {
	const requestOptions = {
		method: "POST",
		headers: getHeaders(),
		body: JSON.stringify(formData)
	};
	await fetch(`${endpoint}/api/v1/device/layout`, requestOptions)
		.then(res => res.json())
		.then(result => {
			return result;
		});
};

export const doGetLayout = async formData => {
	try {
		const requestOptions = {
			method: "POST",
			headers: getHeaders(),
			body: JSON.stringify(formData)
		};
		return await fetch(
			`${endpoint}/api/v1/device/getlayout`,
			requestOptions
		)
			.then(res => res.json())
			.then(result => {
				return result;
			});
	} catch (err) {
		console.log(`layout`, err);
	}
};

export const doSavePassword = async formData => {
	const requestOptions = {
		method: "POST",
		headers: getHeaders(),
		body: JSON.stringify(formData)
	};
	return await fetch(`${endpoint}/api/v1/user/password`, requestOptions)
		.then(res => res.json())
		.then(
			result => {
				if (!result.error) {
					localStorage.setItem("user", JSON.stringify(result));
					setGlobal({
						user: result
					});
				} else {
					throw new Error(result.error);
				}
			},
			error => {
				console.log(error);
			}
		);
};

export const doSaveProfile = async formData => {
	const requestOptions = {
		method: "POST",
		headers: getHeaders(),
		body: JSON.stringify(formData)
	};
	await fetch(`${endpoint}/api/v1/user/update`, requestOptions)
		.then(res => res.json())
		.then(
			result => {
				console.log(result);
				localStorage.setItem("user", JSON.stringify(result));
				setGlobal({
					user: result
				});
			},
			error => {
				console.log(error);
			}
		);
};

export const doResetPassword = async email => {
	return await fetch(`${endpoint}/api/v1/user/resetpassword/${email}`).then(
		result => {
			if (result.status === 200) {
				return "Er is een email naar u verstuurd!";
			} else {
				return result;
			}
		}
	);
};

export const doRemoveDevice = async formData => {
	const requestOptions = {
		method: "POST",
		headers: getHeaders(),
		body: JSON.stringify(formData)
	};
	return await fetch(`${endpoint}/api/v1/device/remove`, requestOptions)
		.then(res => res.json())
		.then(
			result => {
				setGlobal({
					devices: result
				});

				return result;
			},
			error => {
				console.log(error);
			}
		);
};

export const doAddDevice = async formData => {
	const requestOptions = {
		method: "POST",
		headers: getHeaders(),
		body: JSON.stringify(formData)
	};
	return await fetch(`${endpoint}/api/v1/device/add`, requestOptions)
		.then(res => res.json())
		.then(
			result => {
				setGlobal({
					devices: result
				});

				return result;
			},
			error => {
				console.log(error);
			}
		);
};

export const doUpdateDevice = async formData => {
	const requestOptions = {
		method: "POST",
		headers: getHeaders(),
		body: JSON.stringify(formData)
	};
	await fetch(`${endpoint}/api/v1/device/update`, requestOptions)
		.then(res => res.json())
		.then(
			result => {
				setGlobal({
					devices: result
				});
			},
			error => {
				console.log(error);
			}
		);
};

export const doAddUser = async formData => {
	const requestOptions = {
		method: "POST",
		headers: getHeaders(),
		body: JSON.stringify(formData)
	};
	return await fetch(`${endpoint}/api/v1/user/add`, requestOptions)
		.then(res => res.json())
		.then(
			result => {
				console.log(result);
				if (!result.error) {
					setGlobal({
						users: result
					});
				}
				return result;
			},
			error => {
				console.log(error);
				setGlobal({
					hasError: true,
					errorMessage:
						"Gebruiker kon niet worden toegevoegd, controleer uw verbinding.."
				});
			}
		);
};

export const doRemoveUser = async formData => {
	const requestOptions = {
		method: "POST",
		headers: getHeaders(),
		body: JSON.stringify(formData)
	};
	return await fetch(`${endpoint}/api/v1/user/delete`, requestOptions)
		.then(res => res.json())
		.then(
			result => {
				setGlobal({
					users: result
				});
			},
			error => {
				console.log(error);
				setGlobal({
					hasError: true,
					errorMessage:
						"Gebruiker niet succesvol verwijderd, controleer uw verbinding.."
				});
			}
		);
};

export const doUserSearch = async formData => {
	const requestOptions = {
		method: "POST",
		headers: getHeaders(),
		body: JSON.stringify(formData)
	};
	return await fetch(`${endpoint}/api/v1/user/search`, requestOptions)
		.then(res => res.json())
		.then(
			result => {
				setGlobal({
					users: result
				});
			},
			error => {
				console.log(error);
			}
		);
};

export const doUpdateUserAdmin = async formData => {
	const requestOptions = {
		method: "POST",
		headers: getHeaders(),
		body: JSON.stringify(formData)
	};
	return await fetch(`${endpoint}/api/v1/admin/updateuser`, requestOptions)
		.then(res => res.json())
		.then(
			result => {
				let fullState = getGlobal();
				let clonedUsers = _.cloneDeep(fullState.users);
				let currentUser = _.cloneDeep(fullState.user);
				for (let user in clonedUsers) {
					if (clonedUsers[user].name === result.name) {
						clonedUsers[user] = result;
						setGlobal({
							users: clonedUsers
						});
					}
				}
				if (currentUser.name === result.name) {
					currentUser = result;
					localStorage.setItem("user", JSON.stringify(currentUser));
					setGlobal({
						user: currentUser,
						status: []
					});
				}
			},
			error => {
				console.log(error);
				setGlobal({
					hasError: true,
					errorMessage:
						"Update niet succesvol, controleer de verbinding.."
				});
			}
		);
};

export const getRawStatus = async device_hash => {
	let requestOptions = {
		method: "GET",
		headers: getHeaders()
	};
	return fetch(`${endpoint}/api/v1/rawstatus/${device_hash}`, requestOptions)
		.then(res => res.json())
		.then(result => {
			return result;
		});
};

export const getStatus = async device_hash => {
	let requestOptions = {
		method: "GET",
		headers: getHeaders()
	};
	return fetch(`${endpoint}/api/v1/status/${device_hash}`, requestOptions)
		.then(res => res.json())
		.then(
			result => {
				if (result.length > 0) {
					setGlobal({
						status: result
					});
					return result;
				}
			},
			error => {
				setGlobal({
					hasError: true,
					errorMessage:
						"Statussen konden niet opgehaald worden, controleer de verbinding.."
				});
			}
		);
};

export const doFill = async (device_hash, networkID, tube) => {
	let requestOptions = {
		method: "GET",
		headers: getHeaders()
	};
	await fetch(
		`${endpoint}/api/v1/status/fill/${device_hash}/${networkID}/${tube}`,
		requestOptions
	)
		.then(res => res.json())
		.then(
			result => {
				// setGlobal({
				//   status: result
				// })
			},
			error => {
				console.log(error);
				setGlobal({
					hasError: true,
					errorMessage:
						"Slurf vullen niet succesvol, controleer de verbinding.."
				});
			}
		);
};

export const doEmpty = async (device_hash, networkID, tube) => {
	let requestOptions = {
		method: "GET",
		headers: getHeaders()
	};
	await fetch(
		`${endpoint}/api/v1/status/empty/${device_hash}/${networkID}/${tube}`,
		requestOptions
	)
		.then(res => res.json())
		.then(
			result => {
				// setGlobal({
				//   status: result
				// })
			},
			error => {
				console.log(error);
				setGlobal({
					hasError: true,
					errorMessage:
						"Slurf legen niet succesvol, controleer de verbinding.."
				});
			}
		);
};

export const doSetReset = async (device_hash, networkID, tube) => {
	let requestOptions = {
		method: "GET",
		headers: getHeaders()
	};
	await fetch(
		`${endpoint}/api/v1/status/reset/${device_hash}/${networkID}/${tube}`,
		requestOptions
	)
		.then(res => res.json())
		.then(
			result => {
				// setGlobal({
				//   status: result
				// })
			},
			error => {
				console.log(error);
				setGlobal({
					hasError: true,
					errorMessage:
						"Slurf legen niet succesvol, controleer de verbinding.."
				});
			}
		);
};
