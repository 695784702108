import React, {
	useEffect,
	useGlobal,
	setGlobal,
	getGlobal,
	useState
} from "reactn";
import { makeStyles } from "@material-ui/styles";
import {
	Grid,
	Typography,
	List,
	ListItem,
	ListItemText,
	Divider,
	Card,
	Button,
	Backdrop,
	Chip,
	ListSubheader,
	CardHeader,
	CardContent
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import _ from "lodash";
import * as API from "../../api/api";
import Cel from "./Components/Cel";
import Icon from "./Components/Icon";
import ActieKnoppen from "./Components/ActieKnoppen";
import Layout from "../Account/components/Layout";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

const useStyles = makeStyles(theme => ({
	root: {
		// padding: theme.spacing(4)
	}
}));

const Dashboard = props => {
	const classes = useStyles();
	const { className, ...rest } = props;

	const [user] = useGlobal("user");
	const [status] = useGlobal("status");
	const [layout, setLayout] = useState({});
	const [isLoading, setIsLoading] = useState(true);
	const [hideLayout, setHideLayout] = useState(false);

	let alreadyRunningLayout = false;
	const getLayout = async () => {
		try {
			if (!alreadyRunningLayout) {
				alreadyRunningLayout = true;
				if (user.device_hash) {
					await API.doGetLayout({
						user: user,
						device_hash: user.device_hash,
						hideLayout: hideLayout
					}).then(r => {
						if (r) {
							if (Object.keys(r).length > 0) {
								setLayout(r);
								setLayoutStatusses(r);
							}
						}
					});
				} else {
					return true;
				}
				alreadyRunningLayout = false;
			}
		} catch (err) {
			console.log(err.message);
		}
	};

	const getData = async () => {
		try {
			if (user.device_hash) {
				await API.getStatus(user.device_hash).then(r => {
					if (r) {
						setGlobal({
							status: r
						});
						getLayout();
					}
				});
			}
		} catch (err) {
			console.log(err.message);
		} finally {
			setIsLoading(false);
		}
	};

	const setLayoutStatusses = async layz => {
		try {
			if (!layz) {
				layz = layout;
			}
			let n = _.cloneDeep(layz);
			let status = getGlobal("status").status;
			if (status && status.length > 0 && Object.keys(n).length > 0) {
				for (let i in n) {
					let items = n[i].items;
					for (let x in items) {
						for (let z in status) {
							if (status[z].networkID === items[x].networkID) {
								items[x].status =
									status[z].status.tubeStatus[
										parseInt(items[x].index)
									];
							}
						}
					}
				}
				setLayout(n);
			}
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		getData();
		getLayout();
	}, []);

	useEffect(() => {
		if (hideLayout) {
			setGlobal({
				status: []
			});
			setLayout({});
			setIsLoading(true);
			getData();
			getLayout();
		}
	}, [hideLayout]);

	useEffect(() => {
		getData();
		getLayout();
	}, [user]);

	useEffect(() => {
		setLayoutStatusses();
	}, [status]);

	return (
		<div className={classes.root}>
			<Grid container>
				{isLoading ? (
					<Grid
						style={{ marginTop: 100, marginBottom: 200 }}
						container
						direction="column"
						justify="center"
						alignItems="center"
					>
						<CircularProgress color="primary" />
						<Typography style={{ marginTop: 50 }}>
							Moment, uw slurven worden opgehaald..
						</Typography>
					</Grid>
				) : (
					<Grid item lg={12} md={12} xl={12} xs={12}>
						<Card className={(classes.root, className)}>
							<CardHeader title="Uw Slurven" />
							<CardContent>
								{user.admin && (
									<div style={{ marginBottom: 25 }}>
										<FormControlLabel
											control={
												<Switch
													checked={hideLayout}
													onChange={e => {
														setHideLayout(
															e.target.checked
														);
													}}
													name="checkedB"
													color="primary"
												/>
											}
											label="Verberg Layout"
										/>
									</div>
								)}

								{layout &&
									Object.keys(layout).length > 0 &&
									Object.keys(layout)
										.filter(
											z => layout[z].name !== "Slurven"
										)
										.map((ev, k) => (
											<List
												key={k}
												component="nav"
												aria-labelledby="nested-list-subheader"
												subheader={
													<Typography
														variant="button"
														display="block"
														style={{
															marginLeft: 10
														}}
													>
														<p>{layout[ev].name}</p>
													</Typography>
												}
												key={Math.floor(
													Math.random() * 50000 + 1
												)}
												// className={classes.root}
											>
												{layout[ev].items.map(
													(ez, ke) => (
														<div
															key={ke}
															style={{
																borderBottom:
																	"1px solid #ebebeb",
																paddingBottom:
																	"0.5rem",
																paddingTop:
																	"0.5rem"
															}}
														>
															<ListItem>
																<Grid
																	container
																	direction="row"
																	justify="space-between"
																	alignItems="center"
																	spacing={3}
																>
																	<Grid
																		item
																		xs={6}
																		md={3}
																	>
																		<Icon
																			code={
																				ez.status
																					? ez.status
																					: 0
																			}
																		/>
																	</Grid>
																	<Grid
																		item
																		style={{
																			textAlign:
																				"left"
																		}}
																	>
																		<Typography
																			variant="button"
																			display="block"
																			style={{
																				marginLeft: 10
																			}}
																		>
																			{
																				ez.content
																			}
																		</Typography>
																	</Grid>
																	{user.admin ? (
																		<Grid
																			item
																			xs={
																				12
																			}
																			md={
																				3
																			}
																		>
																			{[
																				"serial",
																				"networkID"
																			].map(
																				(
																					ev,
																					k
																				) => (
																					<Chip
																						style={{
																							textTransform:
																								"capitalize",
																							margin:
																								".2rem"
																						}}
																						label={`${ev} : ${ez[ev]}`}
																						key={
																							k
																						}
																					/>
																				)
																			)}
																		</Grid>
																	) : (
																		""
																	)}
																	<Grid
																		item
																		xs={12}
																		md={2}
																	>
																		<ActieKnoppen
																			status={
																				ez.status
																					? ez.status
																					: 0
																			}
																			networkID={
																				ez.networkID
																			}
																			nummer={
																				ez.index
																			}
																		/>
																	</Grid>
																</Grid>
															</ListItem>
														</div>
													)
												)}
											</List>
										))}
								{Object.keys(layout).length === 0 &&
								status.length > 0
									? status
											.sort(
												(a, b) =>
													a.networkID - b.networkID
											)
											.map((item, i) => {
												return (
													<div key={i}>
														{JSON.stringify(
															item.status
																.networkID
														)}
														<Cel
															key={i}
															data={status[i]}
															totalTubes={500}
														/>
													</div>
												);
											})
									: ""}
							</CardContent>
						</Card>
					</Grid>
				)}
			</Grid>
		</div>
	);
};

export default Dashboard;
