import React, { useState, useGlobal, setGlobal } from "reactn";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import * as API from "../../../../api/api";
import {
	Card,
	CardHeader,
	CardContent,
	CardActions,
	Divider,
	Snackbar,
	Grid,
	Button,
	TextField
} from "@material-ui/core";
import { useEffect } from "react";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(() => ({
	root: {}
}));

const AccountDetails = props => {
	const { className, ...rest } = props;

	const classes = useStyles();

	const [globalUser] = useGlobal("user");
	const [hasDone] = useGlobal("hasDone");

	const [values, setValues] = useState({
		name: globalUser.name,
		email: globalUser.email,
		locatie: globalUser.locatie
	});

	const handleChange = event => {
		setValues({
			...values,
			[event.target.name]: event.target.value
		});
	};

	const onSave = async () => {
		try {
			await API.doSaveProfile(values);
		} catch (err) {
			console.log(err);
		} finally {
			setGlobal({
				hasDone: true
			});
		}
	};

	return (
		<Card {...rest} className={clsx(classes.root, className)}>
			<Snackbar
				open={hasDone}
				autoHideDuration={6000}
				onClose={() => {
					setGlobal({ hasDone: false });
				}}
			>
				<Alert severity="info">Wijzigingen opgeslagen</Alert>
			</Snackbar>
			<form autoComplete="off" noValidate>
				<CardHeader
					subheader="Wijzig hier uw gegevens"
					title="Mijn Profiel"
				/>
				<Divider />
				<CardContent>
					<Grid container spacing={3}>
						<Grid item md={6} xs={12}>
							<TextField
								fullWidth
								helperText="Voer een gebruikersnaam in"
								label="Gebruikersnaam"
								margin="dense"
								name="name"
								onChange={handleChange}
								required
								disabled
								value={values.name}
								variant="outlined"
							/>
						</Grid>
						<Grid item md={6} xs={12}>
							<TextField
								fullWidth
								label="Email Address"
								margin="dense"
								name="email"
								onChange={handleChange}
								required
								value={values.email}
								variant="outlined"
							/>
						</Grid>
						<Grid item md={6} xs={12}>
							<TextField
								fullWidth
								label="Locatie"
								margin="dense"
								name="locatie"
								onChange={handleChange}
								required
								value={values.locatie}
								variant="outlined"
							/>
						</Grid>
					</Grid>
				</CardContent>
				<Divider />
				<CardActions>
					<Button
						color="primary"
						variant="contained"
						onClick={() => {
							onSave();
						}}
					>
						Opslaan
					</Button>
				</CardActions>
			</form>
		</Card>
	);
};

AccountDetails.propTypes = {
	className: PropTypes.string
};

export default AccountDetails;
