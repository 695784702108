import React, { useState, setGlobal } from "reactn";
import clsx from "clsx";
import PropTypes from "prop-types";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@material-ui/styles";
import {
	Card,
	CardActions,
	CardContent,
	Table,
	Dialog,
	DialogContent,
	DialogContentText,
	DialogTitle,
	DialogActions,
	Button,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
	TextField,
	IconButton,
	TablePagination
} from "@material-ui/core";
import CommentIcon from "@material-ui/icons/Comment";
import DeleteIcon from "@material-ui/icons/Delete";
import _ from "lodash";
import * as API from "../../../../api/api";
import Layout from "../../../Account/components/Layout/Layout";
import localization from "moment/locale/nl";
import ViewDayIcon from "@material-ui/icons/ViewDay";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
moment.locale("nl", localization);

const useStyles = makeStyles(theme => ({
	root: {},
	content: {
		padding: 0
	},
	inner: {
		minWidth: 1050
	},
	nameContainer: {
		display: "flex",
		alignItems: "center"
	},
	avatar: {
		marginRight: theme.spacing(2)
	},
	actions: {
		justifyContent: "flex-end"
	},
	form: {
		display: "flex",
		flexDirection: "column",
		margin: "auto",
		width: "fit-content"
	},
	formControl: {
		marginTop: theme.spacing(2),
		minWidth: 120
	},
	formControlLabel: {
		marginTop: theme.spacing(1)
	}
}));

const DevicesTable = props => {
	const { className, devices, ...rest } = props;

	const classes = useStyles();

	const [selectedDevices, setselectedDevices] = useState([]);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [page, setPage] = useState(0);
	const [layoutDeviceHash, setLayoutDeviceHash] = useState("");

	const handleSelectAll = event => {
		const { devices } = props;

		let selectedDevices;

		if (event.target.checked) {
			selectedDevices = devices.map(device => device.id);
		} else {
			selectedDevices = [];
		}

		setselectedDevices(selectedDevices);
	};

	const handleSelectOne = (event, id) => {
		const selectedIndex = selectedDevices.indexOf(id);
		let newselectedDevices = [];

		if (selectedIndex === -1) {
			newselectedDevices = newselectedDevices.concat(selectedDevices, id);
		} else if (selectedIndex === 0) {
			newselectedDevices = newselectedDevices.concat(
				selectedDevices.slice(1)
			);
		} else if (selectedIndex === selectedDevices.length - 1) {
			newselectedDevices = newselectedDevices.concat(
				selectedDevices.slice(0, -1)
			);
		} else if (selectedIndex > 0) {
			newselectedDevices = newselectedDevices.concat(
				selectedDevices.slice(0, selectedIndex),
				selectedDevices.slice(selectedIndex + 1)
			);
		}

		setselectedDevices(newselectedDevices);
	};

	const handlePageChange = (event, page) => {
		setPage(page);
	};

	const handleRowsPerPageChange = event => {
		setRowsPerPage(event.target.value);
	};

	const [open, setOpen] = useState(false);
	const [layoutOpen, setLayoutOpen] = useState(false);
	const [currentDeviceHash, setCurrentDeviceHash] = useState("");
	const [currentComment, setCurrentComment] = useState("");
	const [emptyStatusModalOpen, setEmptyStatusModalOpen] = useState(false);
	const [deviceHashToClearStatus, setDeviceHashToClearStatus] = useState("");
	const handleClickOpen = (device_hash, comment) => {
		setCurrentDeviceHash(device_hash);
		setCurrentComment(comment);
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		setCurrentDeviceHash("");
		setCurrentComment("");
	};

	const handleKenmerkChange = _.debounce((device_hash, event) => {
		if (event) {
			API.doUpdateDevice({ device_hash: device_hash, kenmerk: event });
		}
	}, 250);

	const removeDevice = async device_hash => {
		await API.doRemoveDevice({ hash: device_hash });
	};

	const saveCommentChange = _.debounce(event => {
		if (currentDeviceHash && event) {
			API.doUpdateDevice({
				device_hash: currentDeviceHash,
				commentaar: event
			});
		}
	}, 250);

	const handleLayoutClose = () => {
		setLayoutOpen(false);
		setLayoutDeviceHash("");
	};

	const handleLayoutOpen = device_hash => {
		setLayoutDeviceHash(device_hash);
		setLayoutOpen(true);
	};

	const showEmptyStatusModal = device_hash => {
		setEmptyStatusModalOpen(true);
		setDeviceHashToClearStatus(device_hash);
	};

	const emptyDeviceStatus = async () => {
		try {
			if (deviceHashToClearStatus) {
				await API.doEmptyStatus(deviceHashToClearStatus).then(r => {
					if (r.status === "OK") {
						setGlobal({
							hasError: true,
							errorMessage: "Status is nu leeg"
						});
					} else {
						setGlobal({
							hasError: true,
							errorMessage: "Kon status niet leegmaken.."
						});
					}
				});
			}
		} catch (err) {
			setGlobal({
				hasError: true,
				errorMessage: "Kon status niet leegmaken.."
			});
		} finally {
			setEmptyStatusModalOpen(false);
		}
	};

	return (
		<>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				fullWidth
			>
				<DialogTitle id="alert-dialog-title">
					{"Commentaar"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						<TextField
							id="outlined-multiline-static"
							multiline
							rows={25}
							defaultValue={currentComment}
							variant="outlined"
							onChange={event => {
								saveCommentChange(event.target.value);
							}}
							fullWidth
						/>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Annuleren
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				open={emptyStatusModalOpen}
				onClose={() => {
					setEmptyStatusModalOpen(false);
				}}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				fullWidth
			>
				<DialogTitle id="alert-dialog-title">
					{"Weet je het zeker?"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						U staat op het punt om alle statussen te verwijderen van{" "}
						<strong>{deviceHashToClearStatus}</strong>, weet je dit
						zeker?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => {
							setEmptyStatusModalOpen(false);
						}}
						color="secondary"
					>
						Annuleren
					</Button>
					<Button
						onClick={() => {
							emptyDeviceStatus();
						}}
						color="primary"
					>
						Leegmaken
					</Button>
				</DialogActions>
			</Dialog>
			<Card {...rest} className={clsx(classes.root, className)}>
				<CardContent className={classes.content}>
					<PerfectScrollbar>
						<div className={classes.inner}>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>Device Hash</TableCell>
										<TableCell>Kenmerk</TableCell>
										<TableCell>Laatste Actie</TableCell>
										<TableCell>Commentaar</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{devices.map((device, key) => (
										<TableRow
											className={classes.tableRow}
											hover
											key={key}
											selected={
												selectedDevices.indexOf(
													device.id
												) !== -1
											}
										>
											<TableCell>
												{device.device_hash}
											</TableCell>
											<TableCell>
												<TextField
													label="Kenmerk"
													defaultValue={
														device.kenmerk
													}
													onChange={event => {
														handleKenmerkChange(
															device.device_hash,
															event.target.value
														);
													}}
												/>
											</TableCell>
											<TableCell>
												{moment(
													device.lastUpdated
												).fromNow()}
												<br />
												{moment(
													device.lastUpdated
												).format(
													"D MMMM YYYY, h:mm:ss a"
												)}
											</TableCell>
											<TableCell>
												<IconButton
													aria-label="delete"
													onClick={() => {
														handleClickOpen(
															device.device_hash,
															device.commentaar
														);
													}}
												>
													<CommentIcon />
												</IconButton>
												<IconButton
													aria-label="delete"
													onClick={() => {
														handleLayoutOpen(
															device.device_hash
														);
													}}
												>
													<ViewDayIcon />
												</IconButton>
												<IconButton
													aria-label="delete"
													onClick={() => {
														showEmptyStatusModal(
															device.device_hash
														);
													}}
												>
													<HourglassEmptyIcon />
												</IconButton>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</div>
					</PerfectScrollbar>
				</CardContent>
			</Card>
			<Dialog
				open={layoutOpen}
				onClose={handleLayoutClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				fullWidth
				fullScreen
			>
				<Layout device_hash={layoutDeviceHash} />
			</Dialog>
		</>
	);
};

DevicesTable.propTypes = {
	className: PropTypes.string,
	devices: PropTypes.array.isRequired
};

export default DevicesTable;
