import React, { useState, useGlobal } from "reactn";
import clsx from "clsx";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@material-ui/styles";
import _ from "lodash";
import {
	Card,
	CardActions,
	CardContent,
	Avatar,
	TextField,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
	TablePagination,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
	IconButton,
	FormControl,
	InputLabel,
	Select,
	MenuItem
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import * as API from "../../../../api/api";
import { getInitials } from "helpers";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles(theme => ({
	root: {},
	content: {
		padding: 0
	},
	inner: {
		minWidth: 1050
	},
	nameContainer: {
		display: "flex",
		alignItems: "center"
	},
	avatar: {
		marginRight: theme.spacing(2)
	},
	actions: {
		justifyContent: "flex-end"
	}
}));

const UsersTable = props => {
	const { className, ...rest } = props;
	const [users] = useGlobal("users");
	const classes = useStyles();

	const [devices] = useGlobal("devices");

	if (devices.length === 0) {
		API.getDevices();
	}

	const [selectedUsers, setSelectedUsers] = useState([]);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [page, setPage] = useState(0);

	const handleSelectAll = event => {
		const { users } = props;

		let selectedUsers;

		if (event.target.checked) {
			selectedUsers = users.map(user => user.id);
		} else {
			selectedUsers = [];
		}

		setSelectedUsers(selectedUsers);
	};

	const handleSelectOne = (event, id) => {
		const selectedIndex = selectedUsers.indexOf(id);
		let newSelectedUsers = [];

		if (selectedIndex === -1) {
			newSelectedUsers = newSelectedUsers.concat(selectedUsers, id);
		} else if (selectedIndex === 0) {
			newSelectedUsers = newSelectedUsers.concat(selectedUsers.slice(1));
		} else if (selectedIndex === selectedUsers.length - 1) {
			newSelectedUsers = newSelectedUsers.concat(
				selectedUsers.slice(0, -1)
			);
		} else if (selectedIndex > 0) {
			newSelectedUsers = newSelectedUsers.concat(
				selectedUsers.slice(0, selectedIndex),
				selectedUsers.slice(selectedIndex + 1)
			);
		}

		setSelectedUsers(newSelectedUsers);
	};

	const handlePageChange = (event, page) => {
		setPage(page);
		console.log((page + 1) * rowsPerPage);
	};

	const handleRowsPerPageChange = event => {
		setRowsPerPage(event.target.value);
	};

	const [open, setOpen] = useState(false);
	const [userToDelete, userToDeleteSet] = useState("");

	const handleClickOpen = user => {
		userToDeleteSet(user);
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		userToDeleteSet("");
	};

	const handleDelete = () => {
		API.doRemoveUser({ name: userToDelete });
		setOpen(false);
	};

	const [userDeviceHash, setUserDeviceHash] = useState({});

	const handleChange = async (user, event) => {
		await API.doUpdateUserAdmin({
			name: user,
			device_hash: event.target.value
		});
	};

	const handleLocationChange = _.debounce((user, event) => {
		if (user && event) {
			API.doUpdateUserAdmin({ name: user, locatie: event });
		}
	}, 250);

	return (
		<>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{"Weet je het zeker?"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						U staat op het punt om{" "}
						<strong>
							{userToDelete
								? userToDelete.charAt(0).toUpperCase() +
								  userToDelete.slice(1)
								: "N/A"}
						</strong>{" "}
						te verwijderen, deze actie kan niet ongedaan worden
						gemaakt.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Annuleren
					</Button>
					<Button
						onClick={handleDelete}
						color="primary"
						variant="contained"
						autoFocus
					>
						Verwijder
					</Button>
				</DialogActions>
			</Dialog>
			<Card {...rest} className={clsx(classes.root, className)}>
				<CardContent className={classes.content}>
					<PerfectScrollbar>
						<div className={classes.inner}>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>Naam</TableCell>
										<TableCell>Email</TableCell>
										<TableCell>Locatie</TableCell>
										<TableCell>Device Hash</TableCell>
										<TableCell>Admin</TableCell>
										<TableCell>Actie</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{users.map((user, key) => (
										<TableRow
											className={classes.tableRow}
											hover
											key={key}
											selected={
												selectedUsers.indexOf(
													user.id
												) !== -1
											}
										>
											<TableCell>
												<div
													className={
														classes.nameContainer
													}
												>
													<Avatar
														className={
															classes.avatar
														}
														src={user.avatarUrl}
													>
														{getInitials(user.name)}
													</Avatar>
													<Typography variant="body1">
														{user.name}
													</Typography>
												</div>
											</TableCell>
											<TableCell>{user.email}</TableCell>
											<TableCell>
												<TextField
													label="Locatie"
													defaultValue={user.locatie}
													onChange={event =>
														handleLocationChange(
															user.name,
															event.target.value
														)
													}
												/>
											</TableCell>
											<TableCell>
												<FormControl
													className={
														classes.formControl
													}
												>
													<Select
														value={user.device_hash}
														label="Device Hash"
														onChange={e => {
															handleChange(
																user.name,
																e
															);
														}}
													>
														{devices.map(
															(ev, k) => (
																<MenuItem
																	key={k}
																	value={
																		ev.device_hash
																	}
																>
																	{ev.kenmerk &&
																		ev
																			.kenmerk
																			.length >
																			0 &&
																		`${ev.kenmerk} - `}
																	{
																		ev.device_hash
																	}
																</MenuItem>
															)
														)}
													</Select>
												</FormControl>
											</TableCell>
											<TableCell>
												{user.admin ? (
													<CheckIcon color="primary" />
												) : (
													<CloseIcon color="secondary" />
												)}
											</TableCell>
											<TableCell>
												<IconButton
													aria-label="delete"
													onClick={() => {
														handleClickOpen(
															user.name
														);
													}}
												>
													<DeleteIcon />
												</IconButton>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</div>
					</PerfectScrollbar>
				</CardContent>
			</Card>
		</>
	);
};

UsersTable.propTypes = {
	className: PropTypes.string,
	users: PropTypes.array.isRequired
};

export default UsersTable;
