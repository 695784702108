import React, { useGlobal } from "reactn";
import { Switch, Redirect } from "react-router-dom";

import { RouteWithLayout } from "./components";
import { Main as MainLayout, Minimal as MinimalLayout } from "./layouts";

import {
	System as SystemView,
	UserList as UserListView,
	Account as AccountView,
	Settings as SettingsView,
	Dashboard as DashboardView,
	Notify as NotifyView,
	DevicesList as DevicesListView,
	SignIn as SignInView,
	NotFound as NotFoundView,
	ForgotPassword as ForgotPasswordView
} from "./views";

const Routes = () => {
	return (
		<Switch>
			<RouteWithLayout
				component={SignInView}
				exact
				key={"login"}
				layout={MinimalLayout}
				path="/login"
			/>
			<Redirect exact from="/" to="/dashboard" />
			<RouteWithLayout
				component={DashboardView}
				exact
				key={"dash"}
				layout={MainLayout}
				path="/dashboard"
			/>
			<RouteWithLayout
				component={NotifyView}
				exact
				key={"notify"}
				layout={MainLayout}
				path="/notify"
			/>
			<RouteWithLayout
				component={UserListView}
				exact
				key={"users"}
				layout={MainLayout}
				path="/users"
			/>
			<RouteWithLayout
				component={DevicesListView}
				exact
				key={"devices"}
				layout={MainLayout}
				path="/devices"
			/>
			<RouteWithLayout
				component={AccountView}
				exact
				key={"account"}
				layout={MainLayout}
				path="/account"
			/>
			<RouteWithLayout
				component={SystemView}
				exact
				key={"system"}
				layout={MainLayout}
				path="/system"
			/>
			<RouteWithLayout
				component={NotFoundView}
				exact
				key={"404"}
				layout={MinimalLayout}
				path="/not-found"
			/>
			<RouteWithLayout
				component={ForgotPasswordView}
				exact
				key={"recovery"}
				layout={MinimalLayout}
				path="/wachtwoordvergeten"
			/>
			<Redirect to="/not-found" />
		</Switch>
	);
};

export default Routes;
