import React from 'react';

export default class NetworkIDFormat extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<>
				{this.props.nummer}
			</>
		);
	}
}
