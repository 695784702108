import React , { useGlobal } from 'reactn';
import { Doughnut } from 'react-chartjs-2';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import * as API from '../../../../api/api'
import { makeStyles, useTheme } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Divider,
  Typography
} from '@material-ui/core';
import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import RefreshIcon from '@material-ui/icons/Refresh';
import TabletMacIcon from '@material-ui/icons/TabletMac';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  chartContainer: {
    position: 'relative',
    height: '300px'
  },
  stats: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center'
  },
  device: {
    textAlign: 'center',
    padding: theme.spacing(1)
  },
  deviceIcon: {
    color: theme.palette.icon
  }
}));

const UsersByDevice = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const theme = useTheme();

  const [system] = useGlobal('system')

  let aantalPerApparaat = [];
  let apparaten = [];
  let percentageApparaten = {};

  let totaalApparaten = 0;

  if (system.deviceTypes) {
    system.deviceTypes.map((ev, k) => {
      totaalApparaten = (totaalApparaten + ev.total)
    })
    
    system.deviceTypes.map((ev, k) => {
      aantalPerApparaat.push(ev.total)
      apparaten.push(ev._id)
      percentageApparaten[ev._id] = (ev.total / totaalApparaten * 100)
    })
  }

  const refreshData = () => {
    API.getSystem()
  }

  const data = {
    datasets: [
      {
        data: aantalPerApparaat,
        backgroundColor: [
          theme.palette.primary.main,
          theme.palette.error.main,
          theme.palette.warning.main
        ],
        borderWidth: 8,
        borderColor: theme.palette.white,
        hoverBorderColor: theme.palette.white
      }
    ],
    labels: apparaten
  };

  const options = {
    legend: {
      display: false
    },
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      borderWidth: 1,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.white,
      titleFontColor: theme.palette.text.primary,
      bodyFontColor: theme.palette.text.secondary,
      footerFontColor: theme.palette.text.secondary
    }
  };

  const devices = [
    {
      title: 'Desktop',
      value: percentageApparaten['desktop'],
      icon: <LaptopMacIcon />,
      color: theme.palette.primary.main
    },
    {
      title: 'Mobiel',
      value: percentageApparaten['phone'],
      icon: <PhoneIphoneIcon />,
      color: theme.palette.primary.main
    }
  ];

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        action={
          <IconButton size="small" onClick={()=>{refreshData()}}>
            <RefreshIcon />
          </IconButton>
        }
        title="Gebruikers per apparaat"
      />
      <Divider />
      <CardContent>
        <div className={classes.chartContainer}>
          <Doughnut
            data={data}
            options={options}
          />
        </div>
        <div className={classes.stats}>
          {devices.map(device => (
            <div
              className={classes.device}
              key={device.title}
            >
              <span className={classes.deviceIcon}>{device.icon}</span>
              <Typography variant="body1">{device.title}</Typography>
              <Typography
                style={{ color: device.color }}
                variant="h2"
              >
                {device.value ? device.value.toFixed(0) : ''}%
              </Typography>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};

UsersByDevice.propTypes = {
  className: PropTypes.string
};

export default UsersByDevice;
