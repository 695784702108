import React, { useGlobal, useEffect, useState } from "reactn";
import { makeStyles } from "@material-ui/styles";
import * as API from "../../api/api";
import { UsersToolbar, UsersTable } from "./components";
import { Typography, Grid } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(3)
	},
	content: {
		marginTop: theme.spacing(2)
	}
}));

const UserList = () => {
	const classes = useStyles();
	const [loading, setLoading] = useState(true);
	const getData = async () => {
		try {
			await API.getUsers().then(r => {
				if (r) {
					setTimeout(() => {
						setLoading(false);
					}, 100);
				}
			});
		} catch (err) {
			console.log(err);
		}
	};
	useEffect(() => {
		getData();
	}, []);

	const [global] = useGlobal();

	return (
		<div className={classes.root}>
			{loading ? (
				<Grid
					style={{ marginTop: 100, marginBottom: 200 }}
					container
					direction="column"
					justify="center"
					alignItems="center"
				>
					<CircularProgress color="primary" />
				</Grid>
			) : (
				<>
					<UsersToolbar />
					<div className={classes.content}>
						<UsersTable users={global.users} key={"userstable"} />
					</div>
				</>
			)}
		</div>
	);
};

export default UserList;
