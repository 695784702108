import React, { getGlobal } from "reactn";
import { Button, CircularProgress, ButtonGroup } from "@material-ui/core/";
import * as API from "../../../api/api";

export default class ActieKnoppen extends React.Component {
	user = getGlobal();

	state = {
		buttonsDisabled: false,
		status: undefined
	};

	componentDidUpdate(prevProps) {
		if (prevProps.status !== this.props.status) {
			this.setState({ status: this.props.status });
			this.forceUpdate();
		}
	}

	componentDidMount() {
		this.setState({ status: this.props.status });
		this.forceUpdate();
	}

	vullenDisabled = code => {
		let disabledCodes = [2, 4, 5, 7, 9, 19, 21];
		if (disabledCodes.includes(code)) {
			return true;
		} else {
			return false;
		}
	};

	legenDisabled = code => {
		let disabledCodes = [3, 6, 8, 19, 21];
		if (disabledCodes.includes(code)) {
			return true;
		} else {
			return false;
		}
	};

	resetDisabled = code => {
		if ((code > 9 || code === 1) && code !== 19) {
			return false;
		} else {
			return true;
		}
	};

	handleVullen = async () => {
		// if (parseInt(this.props.status) === 5 || parseInt(this.props.status) === 6)
		// 	return;
		this.setState({
			vullenLoading: true
		});

		console.log(`VULLEN => ${this.props.networkID} , ${this.props.nummer}`);

		await API.doFill(
			this.global.user.device_hash,
			this.props.networkID,
			parseInt(this.props.nummer)
		);

		setTimeout(() => {
			this.setState({ vullenLoading: false });
		}, 100);
	};

	handleLegen = async () => {
		this.setState({
			legenLoading: true
		});
		console.log(`LEGEN => ${this.props.networkID} , ${this.props.nummer}`);
		await API.doEmpty(
			this.global.user.device_hash,
			this.props.networkID,
			parseInt(this.props.nummer)
		);

		setTimeout(() => {
			this.setState({ legenLoading: false });
		}, 100);
	};

	handleSetReset = async () => {
		this.setState({
			resetLoading: true
		});
		console.log(`RESET => ${this.props.networkID} , ${this.props.nummer}`);
		await API.doSetReset(
			this.global.user.device_hash,
			this.props.networkID,
			parseInt(this.props.nummer)
		);

		setTimeout(() => {
			this.setState({ resetLoading: false });
		}, 100);
	};

	render() {
		return (
			<>
				<ButtonGroup variant="contained" style={{ width: "100%" }}>
					<Button
						color="primary"
						variant="contained"
						onClick={() => {
							this.handleVullen();
						}}
						style={{ width: "50%" }}
						disabled={
							this.state.vullenLoading ||
							this.vullenDisabled(this.props.status)
						}
					>
						{this.state.vullenLoading ? (
							<CircularProgress size={23} thickness={4} />
						) : (
							"Vullen"
						)}
					</Button>
					<Button
						color="primary"
						variant="contained"
						onClick={() => {
							this.handleLegen();
						}}
						disabled={
							this.state.legenLoading ||
							this.legenDisabled(this.props.status)
						}
						style={{ width: "50%" }}
					>
						{this.state.legenLoading ? (
							<CircularProgress size={23} thickness={4} />
						) : (
							"Legen"
						)}
					</Button>
					{this.user.user.admin && (
						<Button
							color="secondary"
							variant="contained"
							onClick={() => {
								this.handleSetReset();
							}}
							disabled={
								this.state.resetLoading ||
								this.resetDisabled(this.props.status)
							}
							style={{ width: "50%" }}
						>
							{this.state.resetLoading ? (
								<CircularProgress size={23} thickness={4} />
							) : (
								"Reset"
							)}
						</Button>
					)}
				</ButtonGroup>
			</>
		);
	}
}
