import React, { useState, setGlobal, useGlobal } from 'reactn';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button, Typography, Select, MenuItem } from '@material-ui/core';
import { SearchInput } from 'components';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as API from '../../../../api/api'
import _ from 'lodash'

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

const UsersToolbar = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [error, setError] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setError('')
    setOpen(false);
  };

  const [values, setValues] = useState({
    name: '',
    email: '',
    password: '',
    locatie: '',
    device_hash: '',
  });


  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });

    console.log(values)
  };

  const handleSaveUser = async () => {
    await API.doAddUser(values).then((r) => {
      if (r.error) {
        return setError(r.error)
      }
      return handleClose()
    })
  }

  const searchChange = _.debounce((event) => {
    if (event) {
      setGlobal({
        users: []
      })
      API.doUserSearch({ search: event })
    } else if (event === '') {
      API.getUsers()
    }
  }, 250)

  const [devices] = useGlobal('devices')

  return (
    <>
      <div>
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">
            <Typography className={classes.title} variant="h4" noWrap>
              Gebruiker Toevoegen
          </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Voeg een gebruiker toe aan het systeem, vergeet niet dat een gebruiker zonder aangewezen
              Device Hash geen data te zien krijgt.
          </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              name="name"
              label="Gebruikersnaam"
              onChange={handleChange}
              type="text"
              fullWidth
            />
            <TextField
              margin="dense"
              name="email"
              label="Email Adres"
              onChange={handleChange}
              type="text"
              fullWidth
            />
            <TextField
              margin="dense"
              name="password"
              label="Wachtwoord"
              onChange={handleChange}
              type="password"
              fullWidth
            />
            <TextField
              margin="dense"
              name="locatie"
              label="Locatie"
              onChange={handleChange}
              type="text"
              fullWidth
            />
            <Select
              label="Device Hash"
              name="device_hash"
              placeholder="Device Hash"
              onChange={handleChange}
              style={{ marginTop: 10 }}
              fullWidth>
              <MenuItem value="" disabled>
                Device Hash
            </MenuItem>
              {devices.map((ev, k) => (
                <MenuItem value={ev.device_hash} key={k}>{ev.device_hash}</MenuItem>
              ))
              }
            </Select>
            <DialogContentText>
              {error}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Annuleren
          </Button>
            <Button onClick={handleSaveUser} color="primary" variant="contained">
              Aanmaken
          </Button>
          </DialogActions>
        </Dialog>
      </div>
      <div
        {...rest}
        className={clsx(classes.root, className)}
      >
        <div className={classes.row}>
          <span className={classes.spacer} />
          <Button
            color="primary"
            variant="contained"
            onClick={handleClickOpen}
          >
            Gebruiker Toevoegen
        </Button>
        </div>
        <div className={classes.row}>
          <SearchInput
            className={classes.searchInput}
            onChange={(event) => { searchChange(event.target.value) }}
            placeholder="Zoek Gebruiker"
          />
        </div>
      </div>
    </>
  );
};

UsersToolbar.propTypes = {
  className: PropTypes.string
};

export default UsersToolbar;
