import React, { useState, useGlobal, setGlobal } from "reactn";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import * as API from "../../../../api/api";
import _ from "lodash";
import {
	Card,
	CardHeader,
	CardContent,
	CardActions,
	Input,
	TextField,
	Divider,
	Grid,
	Button,
	Typography,
	IconButton,
	Snackbar
} from "@material-ui/core";
import { useEffect } from "react";
import MuiAlert from "@material-ui/lab/Alert";
import uuid from "uuid/v4";
import DeleteIcon from "@material-ui/icons/Delete";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import AdjustIcon from "@material-ui/icons/Adjust";

const useStyles = makeStyles(theme => ({
	root: {
		"& label": {
			color: "white"
		},
		"& label.Mui-focused": {
			color: "white"
		},
		"& .MuiInput-underline:after": {
			borderBottomColor: "white"
		},
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				borderColor: "white"
			},
			"&:hover fieldset": {
				borderColor: "white"
			},
			"&.Mui-focused fieldset": {
				borderColor: "white"
			}
		}
	}
}));

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Layout = props => {
	const { className, ...rest } = props;
	const [allMyStatusses, setAllMyStatusses] = useState([]);

	const [columns, setColumns] = useState({});
	const [user] = useGlobal("user");
	const [hasDone] = useGlobal("hasDone");
	const [columnName, setColumnName] = useState("");

	const [error, setError] = useState("");
	const [deviceHash, setDeviceHash] = useState(
		props.device_hash ? props.device_hash : user.device_hash
	);
	const [allAvailableStatusses, setAllAvailableStatusses] = useState([]);

	useEffect(() => {
		getAllStatusses();
	}, []);

	const getAllStatusses = async () => {
		try {
			let layout = await API.doGetLayout({
				device_hash: deviceHash
			});

			if (layout && Object.keys(layout).length > 0) {
				return setColumns(layout);
			}

			let statusses = await API.getRawStatus(deviceHash);
			let allMyStatus = [];
			if (statusses && statusses.status) {
				let status = statusses.status;
				for (let x in status) {
					let ts = status[x].status.tubeStatus;
					for (let z in ts) {
						console.log(
							statusses.device_hash,
							status[x].networkID,
							z
						);

						allMyStatus.push({
							id: `${statusses.device_hash}${status[x].networkID}${z}`,
							content: `Slurf ${z}`,
							device_hash: statusses.device_hash,
							networkID: status[x].networkID,
							serial: status[x].serial,
							index: z
						});
					}
					setAllMyStatusses(allMyStatus);

					setColumns({
						...columns,
						[uuid()]: {
							name: "Slurven",
							items: allMyStatus
						}
					});
				}
			}
		} catch (err) {
			console.log(err);
		} finally {
			resyncLayout();
		}
	};

	const addColumn = () => {
		setColumns({
			...columns,
			[uuid()]: {
				name: columnName,
				items: []
			}
		});
	};

	const deleteColumn = columnName => {
		let uuidToMoveTo = undefined;
		for (let x in columns) {
			if (columns[x].name === "Slurven") {
				uuidToMoveTo = x;
			}
		}
		let p = columns[columnName].items;
		for (let z in p) {
			columns[uuidToMoveTo].items.push(p[z]);
		}
		delete columns[columnName];
		setColumns({
			...columns
		});
	};

	const onDragEnd = async (result, columns, setColumns) => {
		if (!result.destination) return;
		const { source, destination } = result;

		if (source.droppableId !== destination.droppableId) {
			const sourceColumn = columns[source.droppableId];
			const destColumn = columns[destination.droppableId];
			const sourceItems = [...sourceColumn.items];
			const destItems = [...destColumn.items];
			const [removed] = sourceItems.splice(source.index, 1);
			destItems.splice(destination.index, 0, removed);
			setColumns({
				...columns,
				[source.droppableId]: {
					...sourceColumn,
					items: sourceItems
				},
				[destination.droppableId]: {
					...destColumn,
					items: destItems
				}
			});
		} else {
			const column = columns[source.droppableId];
			const copiedItems = [...column.items];
			const [removed] = copiedItems.splice(source.index, 1);
			copiedItems.splice(destination.index, 0, removed);
			setColumns({
				...columns,
				[source.droppableId]: {
					...column,
					items: copiedItems
				}
			});
		}
	};

	const updateDatabase = async () => {
		await API.doUpdateLayout({
			device_hash: deviceHash,
			layout: columns
		});
	};

	const saveLayout = () => {
		try {
			updateDatabase();
		} catch (err) {
			console.log(err);
		} finally {
			setGlobal({
				hasDone: true
			});
		}
	};

	const handleChange = value => {
		setColumnName(value);
	};

	const setContentName = (e, item) => {
		let columnsD = _.cloneDeep(columns);
		for (let i in columnsD) {
			let col = columnsD[i].items;
			for (let x in col) {
				if (col[x].id === item.id) {
					col[x].content = e.target.value;
				}
			}
		}
		return setColumns(columnsD);
	};

	const moveColumn = (id, direction) => {
		try {
			let bakColumns = _.cloneDeep(columns);
			let keys = Object.keys(bakColumns);
			let curIndex = keys.indexOf(id);
			const calcMove = (index, direction) => {
				if (direction === "left") {
					let dir = index - 1;
					if (dir === 0) {
						return 1;
					} else {
						return index - 1;
					}
				} else if (direction === "right") {
					return index + 1;
				}
			};
			const newIndex = calcMove(curIndex, direction);
			const insert = (arr, index, newItem) => {
				if (direction === "left") {
					return [
						...arr.slice(0, index),
						newItem,
						...arr.slice(index)
					];
				}
			};

			const result = insert(keys, newIndex, id, direction);

			let newObj = {};

			for (let x in result) {
				newObj[result[x]] = bakColumns[result[x]];
			}

			return setColumns(newObj);
		} catch (err) {
			console.log(err);
		}
	};

	const removeLayout = async () => {
		try {
			await API.doDeleteLayout({
				device_hash: deviceHash
			}).then(r => {
				setColumns({});
			});
		} catch (err) {
			console.log(err);
		} finally {
			setTimeout(() => {
				getAllStatusses();
			}, 2500);
		}
	};

	const resyncLayout = async () => {
		try {
			let statusses = await API.getRawStatus(deviceHash);

			// columns section
			let usedColumnUuid = [];
			for (let x in columns) {
				let curColumnItems = columns[x].items;
				for (let y in curColumnItems) {
					let item = curColumnItems[y];
					let uuid = `${item.networkID}-${item.serial}-${item.index}`;
					usedColumnUuid.push(uuid);
				}
			}

			let allPossibleIds = [];

			let pureStatus = statusses.status;
			let allMissingStatusses = [];
			for (let z in pureStatus) {
				let item = pureStatus[z];
				let s = item.status.tubeStatus;
				for (let u in s) {
					let uuid = `${item.networkID}-${item.serial}-${u}`;
					if (!usedColumnUuid.includes(uuid)) {
						console.log(
							`${uuid} is not a column yet, should add that`
						);
						allMissingStatusses.push({
							id: `${statusses.device_hash}${item.networkID}${u}`,
							content: `Slurf ${u}`,
							device_hash: statusses.device_hash,
							networkID: item.networkID,
							serial: item.serial,
							index: u
						});
					}

					allPossibleIds.push(
						`${statusses.device_hash}${item.networkID}${u}`
					);
				}
			}

			setAllAvailableStatusses(allPossibleIds);

			let uuidToUseAsMain = undefined;
			let columnKeys = Object.keys(columns);
			for (let p in columnKeys) {
				if (columns[columnKeys[p]].name === "Slurven") {
					uuidToUseAsMain = columnKeys[p];
				}
			}

			setColumns({
				...columns,
				[uuidToUseAsMain]: {
					name: "Slurven",
					items: columns[uuidToUseAsMain].items.concat(
						allMissingStatusses
					)
				}
			});
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		updateDatabase();
	}, [columns]);

	const classes = useStyles();

	return (
		<Card {...rest} className={clsx(classes.root, className)}>
			<Snackbar
				open={hasDone}
				autoHideDuration={6000}
				onClose={() => {
					setGlobal({ hasDone: false });
				}}
			>
				<Alert severity="info">Layout opgeslagen</Alert>
			</Snackbar>
			<CardHeader
				subheader="Wijzig de layout van de slurven"
				title="Layout"
			/>
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					<Grid item md={12} xs={12}>
						<Button
							onClick={() => {
								resyncLayout();
							}}
							style={{ marginLeft: 10 }}
							color="primary"
							variant="contained"
						>
							Ververs
						</Button>
						<Button
							onClick={() => {
								removeLayout();
							}}
							style={{ marginLeft: 10 }}
							variant="contained"
							color="secondary"
						>
							Verwijder Layout
						</Button>
						<Button
							onClick={() => addColumn()}
							disabled={!columnName}
						>
							Kolom toevoegen
						</Button>
						<Input
							placeholder="Kolom naam"
							onChange={e => {
								handleChange(e.target.value);
							}}
						/>
					</Grid>
					<Grid item md={12} xs={12}>
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								height: "900px",
								overflow: "scroll"
							}}
						>
							<DragDropContext
								onDragEnd={result =>
									onDragEnd(result, columns, setColumns)
								}
							>
								{Object.entries(columns).map(
									([columnId, column], index) => {
										return (
											<div
												style={{
													display: "flex",
													flexDirection: "column",
													alignItems: "center"
												}}
												key={columnId}
											>
												<Typography
													className={classes.name}
													variant="h5"
													style={{
														minHeight: 20
													}}
												>
													{column.name !==
														"Slurven" && (
														<Button
															onClick={() => {
																moveColumn(
																	columnId,
																	"left"
																);
															}}
														>
															<h1>❮</h1>
														</Button>
													)}

													{column.name}

													{column.name !==
														"Slurven" && (
														<IconButton
															onClick={() => {
																deleteColumn(
																	columnId
																);
															}}
															disabled={
																column.name ===
																"Slurven"
															}
														>
															<DeleteIcon />
														</IconButton>
													)}
												</Typography>

												<div style={{ margin: 8 }}>
													<Droppable
														droppableId={columnId}
														key={columnId}
													>
														{(
															provided,
															snapshot
														) => {
															return (
																<div
																	{...provided.droppableProps}
																	ref={
																		provided.innerRef
																	}
																	style={{
																		background: snapshot.isDraggingOver
																			? "lightblue"
																			: "lightgrey",
																		padding: 4,
																		width: 250,
																		minHeight: 500
																	}}
																>
																	{column.items.map(
																		(
																			item,
																			index
																		) => {
																			return (
																				<Draggable
																					key={
																						item.id
																					}
																					draggableId={
																						item.id
																					}
																					index={
																						index
																					}
																				>
																					{(
																						provided,
																						snapshot
																					) => {
																						return (
																							<div
																								ref={
																									provided.innerRef
																								}
																								{...provided.draggableProps}
																								{...provided.dragHandleProps}
																								style={{
																									userSelect:
																										"none",
																									padding: 16,
																									margin:
																										"0 0 8px 0",
																									minHeight:
																										"50px",
																									backgroundColor: snapshot.isDragging
																										? "#263B4A"
																										: "#456C86",
																									color:
																										"white",
																									...provided
																										.draggableProps
																										.style
																								}}
																							>
																								<form
																									className={
																										classes.root
																									}
																									noValidate
																									autoComplete="off"
																								>
																									<TextField
																										label={
																											item.content
																										}
																										color="secondary"
																										size="small"
																										id="outlined-start-adornment"
																										style={{
																											marginBottom: 10
																										}}
																										className={clsx(
																											classes.margin,
																											classes.textField
																										)}
																										variant="outlined"
																										onChange={(
																											e,
																											d
																										) =>
																											setContentName(
																												e,
																												item
																											)
																										}
																									/>
																								</form>
																								NetworkID:{" "}
																								{
																									item.networkID
																								}
																								<br />
																								Serial:{" "}
																								{
																									item.serial
																								}
																								<br />
																								Index:{" "}
																								{
																									item.index
																								}
																								<br />
																								Status:{" "}
																								{
																									<span
																										style={{
																											fontSize: 36,
																											color: allAvailableStatusses.includes(
																												item.id
																											)
																												? "green"
																												: "red"
																										}}
																									>
																										●
																									</span>
																								}
																							</div>
																						);
																					}}
																				</Draggable>
																			);
																		}
																	)}
																	{
																		provided.placeholder
																	}
																</div>
															);
														}}
													</Droppable>
												</div>
											</div>
										);
									}
								)}
							</DragDropContext>
						</div>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

Layout.propTypes = {
	className: PropTypes.string
};

export default Layout;
