import React, { useGlobal, useEffect, useState } from "reactn";
import { makeStyles } from "@material-ui/styles";
import {
	Button,
	Dialog,
	DialogActions,
	Typography,
	DialogContentText,
	DialogContent,
	Grid,
	DialogTitle,
	Input
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as API from "../../api/api";
import { DevicesToolbar, DevicesTable } from "./components";

const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(3)
	},
	content: {
		marginTop: theme.spacing(2)
	}
}));

const DevicesList = () => {
	const classes = useStyles();

	const [open, setOpen] = useState(false);
	const [deviceToAdd, setDeviceToAdd] = useState("");
	const [kenmerkToAdd, setKenmerkToAdd] = useState("");
	const [loading, setLoading] = useState(true);

	const getDevices = async () => {
		try {
			await API.getDevices();
		} catch (err) {
			console.log(err);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getDevices();
	}, []);

	const [global] = useGlobal();

	const handleClose = () => {
		setOpen(false);
	};

	const handleOpen = () => {
		setOpen(true);
	};

	const handleChange = deviceHashInput => {
		setDeviceToAdd(deviceHashInput);
	};

	const handleChange_kenmerk = kenmerkInput => {
		setKenmerkToAdd(kenmerkInput);
	}

	const saveDevice = () => {
		API.doAddDevice({ hash: deviceToAdd, kenmerk: kenmerkToAdd }).then(r => {
			if (r) {
				setOpen(false);
				setDeviceToAdd("");
				setKenmerkToAdd("");
			}
		});
	};

	return (
		<div className={classes.root}>
			{loading ? (
				<Grid
					style={{ marginTop: 100, marginBottom: 200 }}
					container
					direction="column"
					justify="center"
					alignItems="center"
				>
					<CircularProgress color="primary" />
				</Grid>
			) : (
					<>
						<Button
							color="primary"
							variant="contained"
							onClick={() => {
								handleOpen();
							}}
						>
							Apparaat toevoegen
					</Button>
						<Dialog
							open={open}
							onClose={handleClose}
							fullWidth
							maxWidth={"sm"}
							aria-labelledby="alert-dialog-title"
							aria-describedby="alert-dialog-description"
						>
							<DialogTitle id="alert-dialog-title">
								{"Apparaat toevoegen"}
							</DialogTitle>
							<DialogContent>
								<DialogContentText id="alert-dialog-description">
									<Input
										placeholder="Voer de gewenste hash in"
										onChange={e => {
											handleChange(e.target.value);
										}}
										fullWidth
									/>
								</DialogContentText>
								<DialogContentText id="alert-dialog-description">
									<Input
										placeholder="Voer het gewenste klant kenmerk/referentie in"
										onChange={e => {
											handleChange_kenmerk(e.target.value);
										}
											//margin="normal"
										}
										fullWidth
									/>
								</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button onClick={handleClose} color="primary">
									Annuleren
							</Button>
								<Button
									onClick={handleClose}
									color="primary"
									onClick={() => {
										saveDevice();
									}}
								>
									Opslaan
							</Button>
							</DialogActions>
						</Dialog>
						<div className={classes.content}>
							<DevicesTable devices={global.devices} />
						</div>
					</>
				)}
		</div>
	);
};

export default DevicesList;
