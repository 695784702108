import React, { useEffect, useGlobal } from "reactn";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import * as API from "../../api/api";
import prettyBytes from "pretty-bytes";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow
} from "@material-ui/core";
import moment from "moment";

import {
	Budget,
	TotalUsers,
	TasksProgress,
	LatestSales,
	UsersByDevice,
	LatestProducts
} from "./components";

const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(4)
	}
}));

const System = () => {
	const classes = useStyles();

	const [user] = useGlobal("user");

	const [datausage, setDatausage] = useGlobal("datausage");

	useEffect(() => {
		API.getSystem();
		API.getStatus(user.device_hash);
		API.doGetDatausage();
	}, []);

	return (
		<div className={classes.root}>
			<Grid container spacing={4}>
				<Grid item lg={3} sm={6} xl={3} xs={12}>
					<Budget />
				</Grid>
				<Grid item lg={3} sm={6} xl={3} xs={12}>
					<TotalUsers />
				</Grid>
				<Grid item lg={3} sm={6} xl={3} xs={12}>
					<TasksProgress />
				</Grid>
				<Grid item lg={8} md={12} xl={9} xs={12}>
					<LatestSales />
				</Grid>
				<Grid item lg={4} md={6} xl={3} xs={12}>
					<UsersByDevice />
				</Grid>
				<Grid item lg={12} md={12} xl={12} xs={12}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Dag</TableCell>
								<TableCell>Aantal Calls</TableCell>
								<TableCell>Response Totaal</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{datausage.map((day, key) => (
								<TableRow
									className={classes.tableRow}
									style={{ backgroundColor: "white" }}
									hover
									key={key}
								>
									<TableCell>{day.day}</TableCell>
									<TableCell>{day.amountOfCalls}</TableCell>
									<TableCell>
										{prettyBytes(day.dataCapacity)}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</Grid>
			</Grid>
		</div>
	);
};

export default System;
