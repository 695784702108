import React, { useState, useGlobal } from "reactn";
import clsx from "clsx";
import PropTypes from "prop-types";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@material-ui/styles";
import _ from "lodash";
import {
	Card,
	CardActions,
	CardContent,
	Avatar,
	TextField,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
	TablePagination,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
	IconButton,
	FormControl,
	InputLabel,
	Select,
	Grid,
	MenuItem
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import * as API from "../../../../api/api";
import { getInitials } from "helpers";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import Icon from "../Icons";
import ListItem from "@material-ui/core/ListItem";

const useStyles = makeStyles(theme => ({
	root: {},
	content: {
		padding: 0
	},
	inner: {
		minWidth: 1050
	},
	nameContainer: {
		display: "flex",
		alignItems: "center"
	},
	avatar: {
		marginRight: theme.spacing(2)
	},
	actions: {
		justifyContent: "flex-end"
	}
}));



const NotifyLogs = props => {
	const { className, ...rest } = props;
	const [users] = useGlobal("users");
	const classes = useStyles();
	const [deviceactionlogs] = useGlobal("deviceactionlogs");
	//const [devices] = useGlobal("devices");	
	const [filter, setFilter] = useState('');
	const [selected] = useGlobal("selected");
	const [selectedUsers, setSelectedUsers] = useState([]);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [page, setPage] = useState(0);

	//const filteredlogs = deviceactionlogs.filter((deviceactionlog) => deviceactionlog.device_hash == 'ca39a3ee5e6b4b0d3255bfef95601890afd80710');
	//const filteredlogs = deviceactionlogs.filter((deviceactionlog) => moment(deviceactionlog.eventTime).format("YYYY-MM-DD H") == '2023-07-13 13');

	//const search = 1;
	/*const filteredlogs = deviceactionlogs.filter((deviceactionlog) => {
		  return deviceactionlog.action.status.tubeStatus.includes(1) || deviceactionlog.action.status.tubeStatus.includes(2);
		});*/
	
	;
	

	return (
		<>
			
			<Card {...rest} className={clsx(classes.root, className)}>
				<CardContent className={classes.content}>
					<PerfectScrollbar>
						<div className={classes.inner}>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>Nummer</TableCell>		
										<TableCell>Device Hash</TableCell>			
										<TableCell>Event Time</TableCell>		
										<TableCell>Tube Status</TableCell>	
										<TableCell>Serial</TableCell>															
									</TableRow>
								</TableHead>
								<TableBody>
									{deviceactionlogs.map((deviceactionlog, key) => (										
										<TableRow
											className={classes.tableRow}
											hover
											key={key}											
										>
											<TableCell>{key+1}</TableCell>
											<TableCell>
												<div
													className={
														classes.nameContainer
													}
												>													
													<Typography variant="body1">
														{deviceactionlog.device_hash}
													</Typography>
												</div>
											</TableCell>
											<TableCell>
											{moment(
												deviceactionlog.eventTime
											).fromNow()}
											<br />
											{moment(
												deviceactionlog.eventTime
											).format(
												"DD MMMM YYYY, h:mm:ss a"
											)}
											</TableCell>	
											<TableCell>
												<ListItem>
													<Grid
														container
														direction="row"
														justify="space-between"
														alignItems="center"
														spacing={3}
													>
														
														{Object.values(deviceactionlog.action.status.tubeStatus).map((state, i) => (
															<Grid key={i} item xs={6} md={3}
															>													
																{state === 0 ||
																	state > 0 ? (
																		<>
																			<Icon code={state} />
																		</>
																	) : (
																		""
																	)}
															</Grid>
															
														))}
														
													</Grid>
												</ListItem>		
											</TableCell>
											<TableCell>
												{deviceactionlog.action.serial}											
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</div>
					</PerfectScrollbar>
				</CardContent>
			</Card>
		</>
	);
};

NotifyLogs.propTypes = {
	className: PropTypes.string,
	deviceactionlogs: PropTypes.array.isRequired
};

export default NotifyLogs;
