import React, {
	useEffect,
	useGlobal,
	setGlobal,
	getGlobal,
	useState
} from "reactn";
import { makeStyles } from "@material-ui/styles";
import {
	Grid,
	Typography,
	List,
	ListItem,
	ListItemText,
	Divider,
	Card,	
	Button,
	Backdrop,
	Chip,
	ListSubheader,
	CardHeader,
	CardContent,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import _ from "lodash";
import * as API from "../../api/api";
import NotifyDetail from "./components/NotifyDetail";
import NotifyLogs from "./components/NotifyLogs/NotifyLogs";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MenuItem from '@material-ui/core/MenuItem';
import Switch from "@material-ui/core/Switch";
import Select from "@material-ui/core/Select";
import moment from "moment";
import localization from "moment/locale/nl";
import Icon from "./components/Icons";

moment.locale("nl", localization);

const useStyles = makeStyles(theme => ({
	root: {
		// padding: theme.spacing(4)
	}
}));

const Notify = props => {
	const classes = useStyles();
	const { className, ...rest } = props;

	const [user] = useGlobal("user");
	const [devicesLogs,setDevicesLogs] = useState([]);
	const [status] = useGlobal("status");
	const [layout, setLayout] = useState({});
	const [isLoading, setIsLoading] = useState(true);
	const [hideLayout, setHideLayout] = useState(false);
	const [data, setData] = useState();

	//const [deviceactionlogshash, setDeviceactionlogshash] = useState();
	
	//let today = new Date().toISOString().substring(0, 10);
	
	const [hash, setHash] = React.useState(user.device_hash);
	const [today, setToday] = React.useState(new Date().toISOString().substring(0, 10));
	const [serial, setSerial] = React.useState('');

	const hashChange = (event) => {
	  setHash(event.target.value);
	};

	const dayChange = (event) => {
		setToday(event.target.value);
	}
	
	const serialChange = (event) => {
		setSerial(event.target.value);
	}

	  
	let alreadyRunningLayout = false;
	
	
	const getLayout = async () => {
		try {
			if (!alreadyRunningLayout) {
				alreadyRunningLayout = true;
				if (user.device_hash) {
					await API.doGetLayout({
						user: user,
						device_hash: user.device_hash,
						hideLayout: hideLayout
					}).then(r => {
						if (r) {
							if (Object.keys(r).length > 0) {
								setLayout(r);
								setLayoutStatusses(r);
							}
						}
					});
				} else {
					return true;
				}
				alreadyRunningLayout = false;
			}
		} catch (err) {
			console.log(err.message);
		}
	};

	const getData = async () => {
		try {
			if (user.device_hash) {
				console.log(user.device_hash);
				let until = today;				
			}
		} catch (err) {
			console.log(err.message);
		} finally {
			setIsLoading(false);
		}
	};
	
	const getDevicesLogs = async (device_hash,until) => {
		console.log(device_hash);
		try {
			await API.getDeviceActionLogHash(device_hash, until);
			setDevicesLogs(deviceactionlogshash);			
		} catch (err) {
			console.log(err);
		} finally {
			setIsLoading(false);
		}
	};
	


	const setLayoutStatusses = async layz => {
		try {
			if (!layz) {
				layz = layout;
			}
			let n = _.cloneDeep(layz);
			let status = getGlobal("status").status;
			if (status && status.length > 0 && Object.keys(n).length > 0) {
				for (let i in n) {
					let items = n[i].items;
					for (let x in items) {
						for (let z in status) {
							if (status[z].networkID === items[x].networkID) {
								items[x].status =
									status[z].status.tubeStatus[
										parseInt(items[x].index)
									];
							}
						}
					}
				}
				setLayout(n);
			}
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		
		getData();
		API.getDevices();
		//API.getDeviceActionLogHash('ca39a3ee5e6b4b0d3255bfef95601890afd80710', '2023-08-02');
		//API.getDeviceActionLog();
		getDevicesLogs(hash, today);		
		getLayout();
		
	}, []);


	
	useEffect(() => {
		if (hash) {
			setGlobal({
				status: []
			});
			//API.getDeviceActionLog();			
			setLayout({});
			setIsLoading(true);
			API.getDevices();
			getDevicesLogs(hash, today);
			getData();			
			getLayout();			
		}
	}, [hash]);

	useEffect(() => {
		if (today) {
			setGlobal({
				status: []
			});
			//API.getDeviceActionLog();			
			setLayout({});
			setIsLoading(true);
			API.getDevices();
			getDevicesLogs(hash, today);
			getData();			
			getLayout();			
		}
	}, [today]);


	useEffect(() => {
		if (hash) {
			setGlobal({
				status: []
			});
			//API.getDeviceActionLog();			
			setLayout({});
			setIsLoading(true);
			API.getDevices();
			getDevicesLogs(hash, today);
			getData();			
			getLayout();			
		}
	}, [hash]);

	useEffect(() => {
		//API.getDeviceActionLogHash();
		//getDevicesLogs('ca39a3ee5e6b4b0d3255bfef95601890afd80710', '2023-08-02');
		getData();
		getLayout();		
	}, [user]);

	useEffect(() => {
		setLayoutStatusses();
	}, [status]);

	const [global] = useGlobal(); 
	const devices = global.devices;
	const deviceactionlogshash = global.deviceactionlogshash;

	//check contents actionlog
	//console.log(JSON.stringify(deviceactionlogshash));
	
	
	const devicesserial = devices.filter((devices) => devices.device_hash == hash);	
	//console.log(JSON.stringify(devicesserial));

	return (
		<div className={classes.root}>
			<Grid container>
				{isLoading ? (
					<Grid
						style={{ marginTop: 100, marginBottom: 200 }}
						container
						direction="column"
						justify="center"
						alignItems="center"
					>
						<CircularProgress color="primary" />
						<Typography style={{ marginTop: 50 }}>
							Moment, uw slurven worden opgehaald..
						</Typography>
					</Grid>
				) : (
					<Grid item lg={12} md={12} xl={12} xs={12}>
						<Card className={(classes.root, className)}>
							<CardHeader title="Uw Slurven" />
							<CardContent>
							<Table>
									<TableHead>
										<TableRow>
											<TableCell width="150" >
											HASH
											</TableCell>
											<TableCell>						
											<Select
												labelId="filter"
												id="filter-select"		
												value={hash}								
												label="filter"
												onChange={hashChange}												
																							
											>
											 
											 <MenuItem disabled value="">
												<em>Selecteer hash</em>
											</MenuItem>				
										{devices.map((device, keys) => (
											<MenuItem 
											key={keys}
											value={device.device_hash}									
											>
												{device.kenmerk} : {device.device_hash}	
											</MenuItem >
											))}	
										</Select>						
											</TableCell>
											<TableCell>	
											
											
											{Object.values(devicesserial).map((item, index) => {											
												
													return (														
														<Select															
															labelId="serial"
															id="filter-serial"		
															value="{serial}"							
															label="serialfilter"
															onChange={serialChange}											
														>            												
															{Object.keys(item.status).map((istatus, i) => {																
															return (
																<MenuItem
																key={item.status[istatus].serial}
																value={item.status[istatus].serial}	
																>
																	{item.status[istatus].serial}
																</MenuItem>			
															);
														})}
														
													</Select>
													);
												
											})}			

											</TableCell>
											<TableCell>	
											<TextField
												id="date"
												label="Datum"
												type="date"
												defaultValue={today}
												className={classes.textField}
												onChange={dayChange}
												InputLabelProps={{
												shrink: true,												
												}}
											/>
											</TableCell>																						
										</TableRow>
									</TableHead>
														
									<TableBody>
							{/* use ? for mapping, becuase first result is empty*/}												
							{deviceactionlogshash?.map((deviceactionlog, key) => (										
										<TableRow
											className={classes.tableRow}
											hover
											key={key}											
										>
											<TableCell>{key+1}</TableCell>
											<TableCell>
												<span
													className={
														classes.nameContainer
													}
												>													
													<Typography variant="body1">
														{deviceactionlog.device_hash}
													</Typography>
												</span>
											</TableCell>
											<TableCell>
											{moment(
												deviceactionlog.eventTime
											).fromNow()}
											<br />
											{moment(
												deviceactionlog.eventTime
											).format(
												"DD MMMM YYYY, h:mm:ss a"
											)}
											</TableCell>	
											<TableCell>
												<ListItem>
													<Grid
														container
														direction="row"
														justify="space-between"
														alignItems="center"
														spacing={3}
													>
														
														{Object.values(deviceactionlog.action.status.tubeStatus).map((state, i) => (
															<Grid key={i} item xs={6} md={3}
															>													
																{state === 0 ||
																	state > 0 ? (
																		<>
																			<Icon code={state} />
																		</>
																	) : (
																		""
																	)}
															</Grid>
															
														))}
														
													</Grid>
												</ListItem>		
											</TableCell>
											<TableCell>
												{deviceactionlog.action.serial}											
											</TableCell>
										</TableRow>
									))}
									</TableBody>
									</Table>	
							</CardContent>
						</Card>
					</Grid>
				)}
			</Grid>
		</div>
	);
};

export default Notify;
